import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { HospitalCollectSearchBox } from 'src/components/Organisms';
import styled from 'styled-components';
import { usePharmacyCollectHooks } from 'src/hooks/pages/use.pharmacy.collect.hooks';
import { PharmacyCollectTable } from 'src/components/Organisms/Pharmacy/PharmacyCollectTable';
export const PharmacyCollect: FC = memo(() => {
  const {
    schedules,
    areas,
    search,
    download,
    universities,
    pharmacies,
    footer,
  } = usePharmacyCollectHooks();
  return (
    <Default
      title={'薬局実習集計結果'}
      isSelect={2}
      contents={
        <>
          地区名を選択すると病院別のエントリー状況が表示されます。　
          <br />
          複数選択が可能。指定しない場合は全て。
        </>
      }
    >
      <HospitalCollectSearchBox
        schedules={schedules}
        areas={areas}
        onSearch={search}
        onDownload={download}
      />
      {footer && (
        <RootStyle>
          <PharmacyCollectTable
            universities={universities}
            entries={pharmacies}
            footer={footer}
          />
        </RootStyle>
      )}
    </Default>
  );
});

const RootStyle = styled.div`
  width: calc(100vw - 64px);
`;
