import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { UniversityEntity } from 'src/domain/university.entity';
import { ColumnsType } from 'antd/es/table/interface';
import { ColumnType } from 'antd/lib/table';
import { PharmacyCollectEntity } from 'src/domain/pharmacy.collect.entity';

const columns: ColumnsType<PharmacyCollectEntity> = [
  {
    title: '薬局名',
    dataIndex: 'name',
    width: 280,
    fixed: 'left',
  },
  {
    title: '薬局コード',
    dataIndex: 'pharmacyNo',
    width: 280,
    fixed: 'left',
  },
];

type Props = {
  universities: UniversityEntity[];
  entries: PharmacyCollectEntity[];
  footer: PharmacyCollectEntity;
};
export const PharmacyCollectTable: FC<Props> = memo((props) => {
  const [cols, setCols] = useState(columns);
  const createColumn = useCallback(
    (period: number) => {
      const func = (value: PharmacyCollectEntity) => {
        let color = '#55B7A5B2';
        switch (period) {
          case 2:
            if (value.secondCount[0] < value.secondCount[-1]) {
              color = '#FFC777';
            }
            if (value.secondCount[0] > value.secondCount[-1]) {
              color = '#FF7AA2B2';
            }
            break;
          case 3:
            if (value.thirdCount[0] < value.thirdCount[-1]) {
              color = '#FFC777';
            }
            if (value.thirdCount[0] > value.thirdCount[-1]) {
              color = '#FF7AA2B2';
            }
            break;
          case 1:
            if (value.firstCount[0] < value.firstCount[-1]) {
              color = '#FFC777';
            }
            if (value.firstCount[0] > value.firstCount[-1]) {
              color = '#FF7AA2B2';
            }
            break;
        }
        return {
          style: { background: color },
        };
      };
      const results: ColumnType<PharmacyCollectEntity>[] = [
        {
          title: '定員',
          width: 60,
          align: 'end',
          render: (value: PharmacyCollectEntity) =>
            period === 2
              ? value.secondCount[-1]
              : period === 3
                ? value.thirdCount[-1]
                : value.firstCount[-1],
          onCell: (value: PharmacyCollectEntity) => func(value),
        },
        {
          title: '全校計',
          width: 60,
          align: 'end',
          render: (value: PharmacyCollectEntity) =>
            period === 2
              ? value.secondCount[0]
              : period === 3
                ? value.thirdCount[0]
                : value.firstCount[0],
          onCell: (value: PharmacyCollectEntity) => func(value),
        },
      ];
      return [
        ...results,
        ...props.universities.map((u) => {
          return {
            title: u.universityOmissionName,
            width: 60,
            align: 'end',
            render: (value: PharmacyCollectEntity) =>
              period === 2
                ? value.secondCount[u.id] ?? 0
                : period === 3
                  ? value.thirdCount[u.id] ?? 0
                  : value.firstCount[u.id] ?? 0,
            onCell: (value: PharmacyCollectEntity) => func(value),
          } as ColumnType<PharmacyCollectEntity>;
        }),
      ];
    },
    [props.universities],
  );
  useEffect(() => {
    if (cols.length === columns.length + props.universities.length * 3) return;
    const c = [...columns];
    c.push({
      title: '1期',
      key: 'firstCount',
      dataIndex: 'firstCount',
      children: createColumn(1),
    });
    c.push({
      title: '2期',
      key: 'secondCount',
      dataIndex: 'secondCount',
      children: createColumn(2),
    });
    c.push({
      title: '3期',
      key: 'thirdCount',
      dataIndex: 'thirdCount',
      children: createColumn(3),
    });

    setCols([...c]);
  }, [props.universities, cols, createColumn]);
  return (
    <Table
      columns={cols}
      bordered
      pagination={false}
      dataSource={props.entries}
      rowKey={'id'}
      scroll={{ x: 'calc(100vw - 64px)', y: '600px' }}
      summary={() => {
        return (
          <>
            <Table.Summary.Row style={{ background: '#DDDDDD' }}>
              <Table.Summary.Cell index={0} colSpan={2}>
                <Typography.Text strong={true}>エントリー総数</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {props.footer.firstCount[-1]}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {props.footer.firstCount[0]}
                </Typography.Text>
              </Table.Summary.Cell>
              {props.universities.map((u) => {
                return (
                  <Table.Summary.Cell index={0} key={u.id} align={'end'}>
                    <Typography.Text strong={true}>
                      {props.footer.firstCount[u.id] ?? 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {props.footer.secondCount[-1]}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {props.footer.secondCount[0]}
                </Typography.Text>
              </Table.Summary.Cell>
              {props.universities.map((u) => {
                return (
                  <Table.Summary.Cell index={0} key={u.id} align={'end'}>
                    <Typography.Text strong={true}>
                      {props.footer.secondCount[u.id] ?? 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {props.footer.thirdCount[-1]}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {props.footer.thirdCount[0]}
                </Typography.Text>
              </Table.Summary.Cell>
              {props.universities.map((u) => {
                return (
                  <Table.Summary.Cell index={0} key={u.id} align={'end'}>
                    <Typography.Text strong={true}>
                      {props.footer.thirdCount[u.id] ?? 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
});
