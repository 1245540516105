import { FC, memo } from 'react';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';
import { EntryEntity } from 'src/domain/entry.entity';

type Props = {
  entry: {
    [key: string]: { [key: string]: EntryEntity };
  };
};
export const HospitalEntryFooter: FC<Props> = memo((props) => {
  return (
    <Flex vertical={true} justify={'stretch'} style={{ alignSelf: 'stretch' }}>
      <Flex
        vertical={false}
        justify={'flex-end'}
        style={{ background: '#000000' }}
      >
        <HeaderStyle vertical={false} justify={'center'} align={'flex-start'}>
          年間
        </HeaderStyle>
        <HeaderStyle vertical={false} justify={'center'} align={'flex-start'}>
          第2期
        </HeaderStyle>
        <HeaderStyle vertical={false} justify={'center'} align={'flex-start'}>
          第3期
        </HeaderStyle>
        <HeaderStyle vertical={false} justify={'center'} align={'flex-start'}>
          第4期
        </HeaderStyle>
      </Flex>
      <Flex vertical={false} align={'center'}>
        <CellStyle
          vertical={false}
          align={'center'}
          style={{ alignSelf: 'stretch', flex: '1 0 0' }}
        >
          <Typography.Text style={{ fontWeight: 'bold' }}>
            自校総エントリー数
          </Typography.Text>
        </CellStyle>
        <CellStyle
          vertical={false}
          align={'center'}
          justify={'flex-end'}
          style={{ width: '160px', height: '45px' }}
        >
          {Object.keys(props.entry)
            .map((area) =>
              Object.keys(props.entry[area])
                .map(
                  (hospital) =>
                    (props.entry[area][hospital].secondCount ?? 0) +
                    (props.entry[area][hospital].thirdCount ?? 0) +
                    (props.entry[area][hospital].fourthCount ?? 0),
                )
                .reduce((sum, current) => {
                  return sum + current;
                }, 0),
            )
            .reduce((sum, current) => {
              return sum + current;
            }, 0)}
        </CellStyle>
        <CellStyle
          vertical={false}
          align={'center'}
          justify={'flex-end'}
          style={{ width: '160px', height: '45px' }}
        >
          {Object.keys(props.entry)
            .map((area) =>
              Object.keys(props.entry[area])
                .map((hospital) => props.entry[area][hospital].secondCount ?? 0)
                .reduce((sum, current) => {
                  return sum + current;
                }, 0),
            )
            .reduce((sum, current) => {
              return sum + current;
            }, 0)}
        </CellStyle>
        <CellStyle
          vertical={false}
          align={'center'}
          justify={'flex-end'}
          style={{ width: '160px', height: '45px' }}
        >
          {Object.keys(props.entry)
            .map((area) =>
              Object.keys(props.entry[area])
                .map((hospital) => props.entry[area][hospital].thirdCount ?? 0)
                .reduce((sum, current) => {
                  return sum + current;
                }, 0),
            )
            .reduce((sum, current) => {
              return sum + current;
            }, 0)}
        </CellStyle>
        <CellStyle
          vertical={false}
          align={'center'}
          justify={'flex-end'}
          style={{ width: '160px', height: '45px' }}
        >
          {Object.keys(props.entry)
            .map((area) =>
              Object.keys(props.entry[area])
                .map((hospital) => props.entry[area][hospital].fourthCount ?? 0)
                .reduce((sum, current) => {
                  return sum + current;
                }, 0),
            )
            .reduce((sum, current) => {
              return sum + current;
            }, 0)}
        </CellStyle>
      </Flex>
    </Flex>
  );
});

const HeaderStyle = styled(Flex)`
  background: #000000;
  border: 1px solid #ffffff;
  color: #ffffff;
  width: 160px;
  padding: 8px;
`;

const CellStyle = styled(Flex)`
  border: 1px solid #000000;
  padding: 8px;
`;
