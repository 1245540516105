import { FC, memo, useCallback, useEffect } from 'react';
import { Flex, Form, Input, Timeline, Typography } from 'antd';
import { ScheduleEntity } from 'src/domain/schedule.entity';
import styled from 'styled-components';
import { SubmitButton } from 'src/components/Atoms/SubmitButton';
import { RangeForm } from 'src/components/Organisms/Schedule/RangeForm';
import { StoreValue } from 'rc-field-form/lib/interface';
import { Dayjs } from 'dayjs';

type Props = {
  schedule: ScheduleEntity;
  onConfirm: (values: ScheduleEntity) => void;
};
export const ScheduleForm: FC<Props> = memo((props) => {
  const [form] = Form.useForm<ScheduleEntity>();
  const validationDate = useCallback(
    (props: string, label: string) => async (_: any, value: StoreValue) => {
      if (!value || value.length === 0) return Promise.resolve();
      if ((form.getFieldValue(props)[1] as Dayjs).unix() > value[0].unix()) {
        return Promise.reject(
          new Error(`${label}より未来の日時を入力してください`),
        );
      }
      return Promise.resolve();
    },
    [form],
  );
  useEffect(() => {
    // フィールドの値が変更されたときにバリデーションを再実行する
    if (form.getFieldInstance('hospitalEntry')) {
    }
  }, [form]);
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '660px' }}
      initialValues={props.schedule}
      onFinish={props.onConfirm}
    >
      <Flex vertical={true}>
        <Form.Item
          label={'スケジュール名'}
          required={true}
          name={'name'}
          rules={[{ required: true, message: '入力してください' }]}
        >
          <Input />
        </Form.Item>
        <TitleStyle level={5} style={{ margin: 0 }}>
          病院マスター管理（事務局 or 病院）
        </TitleStyle>
        <Timeline
          items={[
            {
              children: (
                <Flex vertical={true} gap={8}>
                  病院マスター登録
                  <TitleStyle level={5}>病院エントリー（大学）</TitleStyle>
                </Flex>
              ),
            },
            {
              children: (
                <Flex vertical={false} gap={32} justify={'space-between'}>
                  エントリー
                  <RangeForm
                    name={'hospitalEntry'}
                    label={'エントリー'}
                    rules={[{ required: true, message: '入力してください' }]}
                    onChange={() => form.validateFields(['hospitalAdjustment'])}
                  />
                </Flex>
              ),
            },
            {
              children: (
                <Flex vertical={false} gap={32} justify={'space-between'}>
                  エントリー数調整
                  <RangeForm
                    name={'hospitalAdjustment'}
                    label={'エントリー数調整'}
                    rules={[
                      { required: true, message: '入力してください' },
                      {
                        validator: validationDate(
                          'hospitalEntry',
                          '病院エントリー',
                        ),
                      },
                    ]}
                    onChange={() => form.validateFields(['hospitalReentry'])}
                  />
                </Flex>
              ),
            },
            {
              children: (
                <Flex vertical={true} gap={8}>
                  <Flex vertical={false} gap={32} justify={'space-between'}>
                    再エントリー
                    <RangeForm
                      name={'hospitalReentry'}
                      label={'再エントリー'}
                      rules={[
                        { required: true, message: '入力してください' },
                        {
                          validator: validationDate(
                            'hospitalAdjustment',
                            '病院エントリー数調整',
                          ),
                        },
                      ]}
                      onChange={() => form.validateFields(['pharmacyMaster'])}
                    />
                  </Flex>
                  <TitleStyle level={5}>
                    薬局マスターメンテナンス（事務局）
                  </TitleStyle>
                </Flex>
              ),
            },
            {
              children: (
                <Flex vertical={true} gap={8}>
                  <Flex vertical={false} gap={32} justify={'space-between'}>
                    薬局マスターアップロード
                    <RangeForm
                      name={'pharmacyMaster'}
                      label={'薬局マスターアップロード'}
                      rules={[
                        { required: true, message: '入力してください' },
                        {
                          validator: validationDate(
                            'hospitalReentry',
                            '病院再エントリー',
                          ),
                        },
                      ]}
                      onChange={() => form.validateFields(['pharmacyEntry'])}
                    />
                  </Flex>
                  <TitleStyle level={5}>薬局エントリー（大学）</TitleStyle>
                </Flex>
              ),
            },
            {
              children: (
                <Flex vertical={true} gap={8}>
                  <Flex vertical={false} gap={32} justify={'space-between'}>
                    エントリー
                    <RangeForm
                      name={'pharmacyEntry'}
                      label={'エントリー'}
                      rules={[
                        { required: true, message: '入力してください' },
                        {
                          validator: validationDate(
                            'pharmacyMaster',
                            '薬局マスターアップロード',
                          ),
                        },
                      ]}
                      onChange={() =>
                        form.validateFields(['pharmacyAdjustment'])
                      }
                    />
                  </Flex>
                  <TitleStyle level={5}>
                    薬局エントリー調整（事務局）
                  </TitleStyle>
                </Flex>
              ),
            },
            {
              children: (
                <Flex vertical={false} gap={32} justify={'space-between'}>
                  薬剤師会調整後再エントリー
                  <RangeForm
                    name={'pharmacyAdjustment'}
                    label={'再エントリー'}
                    rules={[
                      { required: true, message: '入力してください' },
                      {
                        validator: validationDate(
                          'pharmacyEntry',
                          '薬局エントリー',
                        ),
                      },
                    ]}
                  />
                </Flex>
              ),
            },
            {
              children: '完了',
            },
          ]}
        />
        <Flex justify={'center'} style={{ width: '100%', margin: '16px' }}>
          <SubmitButton form={form}>確認する</SubmitButton>
        </Flex>
      </Flex>
    </Form>
  );
});

const TitleStyle = styled(Typography.Title)`
  margin: 16px 0 0 0;
`;
