import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Flex } from 'antd';
import { HospitalForm } from 'src/components/Organisms/Master/HospitalForm';
import { useHospitalMasterEditHooks } from 'src/hooks/pages/use.hospital.master.edit.hooks';
import { HospitalConfirm } from 'src/components/Organisms/Master/HospitalConfirm';

export const HospitalMasterEdit: FC = memo(() => {
  const { hospital, onConfirm, isConfirm, onUpdate, onBack, areas, groups } =
    useHospitalMasterEditHooks();
  return (
    <Default
      title={`${hospital?.name}　マスター情報メンテナンス`}
      isSelect={4}
      contents={
        <Flex vertical={true} gap={8}>
          <div>期別に実習生受入人数を入力してください。</div>
          <div>プロフィール情報に誤りがあれば修正入してください。</div>
          <div style={{ color: 'red' }}>※印は入力必須項目です。</div>
        </Flex>
      }
    >
      {!isConfirm && hospital && (
        <HospitalForm
          areas={areas}
          groups={groups}
          isEdit={true}
          hospital={hospital}
          onConfirm={onConfirm}
        />
      )}
      {isConfirm && hospital && (
        <HospitalConfirm
          isEdit={true}
          hospital={hospital}
          onUpdate={onUpdate}
          onBack={onBack}
        />
      )}
    </Default>
  );
});
