import { FC, memo } from 'react';
import { DatePicker, Flex, Form, Input } from 'antd';
import { SubmitButton } from 'src/components/Atoms/SubmitButton';
import { NewsEntity } from 'src/domain/news.entity';
type Props = {
  news: NewsEntity;
  onConfirm: (values: NewsEntity) => void;
};
export const NewsForm: FC<Props> = memo((props) => {
  const [form] = Form.useForm<NewsEntity>();
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '600px' }}
      initialValues={props.news}
      onFinish={props.onConfirm}
    >
      <Flex
        vertical={true}
        style={{ border: '1px solid #00000070', padding: '16px' }}
      >
        <Form.Item name="id" noStyle />
        <Form.Item name="invalid" noStyle />
        <Form.Item
          label={'お知らせ日'}
          required={true}
          name={'date'}
          rules={[{ required: true, message: '入力してください' }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          label={'内容'}
          required={true}
          name={'content'}
          rules={[
            { required: true, message: '入力してください' },
            { max: 200, message: '200文字以内で入力してください' },
          ]}
        >
          <Input />
        </Form.Item>
      </Flex>
      <Flex justify={'center'} style={{ width: '100%', margin: '16px' }}>
        <SubmitButton form={form}>確認</SubmitButton>
      </Flex>
    </Form>
  );
});
