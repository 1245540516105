import { ScheduleEntity } from 'src/domain/schedule.entity';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { scheduleRepository } from 'src/service/schedule.repository';
import { AccountEntity } from 'src/domain/account.entity';
import { useNavigate } from 'react-router-dom';
import { InfoContext } from 'src/providers/info.provider';
export type ScheduleContextType = {
  schedule?: ScheduleEntity;
  user?: AccountEntity;
  load: () => Promise<void>;
  logout: () => void;
  setUser: (user: AccountEntity) => void;
};

export const ScheduleContext = createContext<ScheduleContextType>(
  {} as ScheduleContextType,
);

export const ScheduleProvider = (props: { children: ReactNode }) => {
  const [schedule, setSchedule] = useState<ScheduleEntity | undefined>();
  const [user, setUser] = useState<AccountEntity | undefined>();
  const { addLoading, removeLoading } = useContext(InfoContext);
  const navigate = useNavigate();
  const load = useCallback(async () => {
    addLoading();
    await scheduleRepository
      .detail()
      .then((data) => {
        setSchedule({
          id: data.data.id,
          name: data.data.name,
          statusId: data.data.statusId,
          hospitalEntry: data.data.hospitalEntry.map((d) => dayjs(d)),
          hospitalAdjustment: data.data.hospitalAdjustment.map((d) => dayjs(d)),
          hospitalReentry: data.data.hospitalReentry.map((d) => dayjs(d)),
          pharmacyMaster: data.data.pharmacyMaster.map((d) => dayjs(d)),
          pharmacyEntry: data.data.pharmacyEntry.map((d) => dayjs(d)),
          pharmacyAdjustment: data.data.pharmacyAdjustment.map((d) => dayjs(d)),
        });
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);
  useEffect(() => {
    console.log('user');
    const storage = localStorage.getItem('user');
    if (storage) {
      load().then();
      setUser(JSON.parse(storage) as AccountEntity);
    }
  }, [load]);

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    setUser(undefined);
    setSchedule(undefined);
    navigate('/login');
  }, []);

  const setLogin = useCallback(
    async (user: AccountEntity) => {
      setUser(user);
      await load();
    },
    [load],
  );

  return (
    <ScheduleContext.Provider
      value={{ schedule, user, load, logout, setUser: setLogin }}
    >
      {props.children}
    </ScheduleContext.Provider>
  );
};
