import { useCallback, useContext, useState } from 'react';
import { AccountCreateEntity } from 'src/domain/account.entity';
import { AuthType } from 'src/common/types';
import { accountRepository } from 'src/service/account.repository';
import { useNavigate } from 'react-router-dom';
import { InfoContext } from 'src/providers/info.provider';

export const useAccountUniversityCreateHooks = () => {
  const [account, setAccount] = useState<AccountCreateEntity>({
    authId: AuthType.University,
    accountId: '',
    password: '',
  });
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);

  const onCreate = useCallback(async () => {
    addLoading();
    await accountRepository
      .create(account)
      .then(() => {
        navigate('/accounts');
        createMessage('大学アカウントを作成しました', 'info');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [account, createMessage]);

  const onBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const onNext = useCallback((values: AccountCreateEntity) => {
    setAccount(values);
    setStep(1);
  }, []);

  return { account, onBack, onNext, onCreate, step };
};
