import { FC, memo } from 'react';
import { Flex, Form, Input, DatePicker } from 'antd';
import { NewsSearchEntity } from 'src/domain/news.entity';
import { SubmitButton } from 'src/components/Atoms/SubmitButton';

type Props = {
  onSearch: (values: NewsSearchEntity) => void;
};

export const NewsSearchBox: FC<Props> = memo((props) => {
  const [form] = Form.useForm<NewsSearchEntity>();
  return (
    <Flex vertical={true} align={'center'}>
      <Form
        layout="vertical"
        form={form}
        initialValues={{ date: [] }}
        onFinish={props.onSearch}
      >
        <Flex vertical={true}>
          <Flex vertical={false} gap={32} align={'center'}>
            <Form.Item label={'お知らせ期間開始'} name={'start'}>
              <DatePicker />
            </Form.Item>
            ~
            <Form.Item name={'end'} label={'終了'}>
              <DatePicker />
            </Form.Item>
            <Form.Item label={'内容'} name={'content'}>
              <Input placeholder={'内容'} />
            </Form.Item>
          </Flex>
          <Flex
            justify={'center'}
            style={{ width: '100%', margin: '16px' }}
            gap={32}
          >
            <SubmitButton form={form}>検索</SubmitButton>
          </Flex>
        </Flex>
      </Form>
    </Flex>
  );
});
