import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { useHospitalMasterSelectHooks } from 'src/hooks/pages/use.hospital.master.select.hooks';
import { GroupHospital } from 'src/components/Organisms/Master/GroupHospital';
import { Flex } from 'antd';

export const HospitalMasterSelect: FC = memo(() => {
  const { groups } = useHospitalMasterSelectHooks();
  return (
    <Default
      title={'病院マスタ情報メンテナンス'}
      isSelect={4}
      contents={'マスター情報のメンテナンスを行う病院を選んでください'}
    >
      <Flex vertical={true} style={{ width: '100%' }}>
        {groups.map((group) => (
          <GroupHospital key={group.areaId} group={group} />
        ))}
      </Flex>
    </Default>
  );
});
