import { FC, memo } from 'react';
import { NewsEntity } from 'src/domain/news.entity';
import { Button, Flex, Form } from 'antd';

type Props = {
  news: NewsEntity;
  onBack: () => void;
  onCreate: () => void;
};
export const NewsConfirm: FC<Props> = memo((props) => {
  return (
    <Form
      layout="vertical"
      style={{ width: '600px' }}
      onFinish={props.onCreate}
    >
      <Flex
        vertical={true}
        style={{ border: '1px solid #00000070', padding: '16px' }}
      >
        <Form.Item label={'お知らせ日'} required={true} name={'date'}>
          {props.news.date.format('YYYY-MM-DD')}
        </Form.Item>
        <Form.Item label={'内容'} required={true} name={'content'}>
          {props.news.content}
        </Form.Item>
      </Flex>
      <Flex
        justify={'center'}
        style={{ width: '100%', margin: '16px' }}
        gap={32}
      >
        <Button onClick={props.onBack}>戻る</Button>
        <Button htmlType={'submit'} type="primary">
          {props.news.id > 0 ? '更新' : '登録'}
        </Button>
      </Flex>
    </Form>
  );
});
