import React, { FC, memo } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { HospitalEntity } from 'src/domain/hospital.entity';

const columns: ColumnsType<HospitalEntity> = [
  {
    title: '地区',
    align: 'start',
    render: (value: HospitalEntity) => value.group?.area.name ?? '',
  },
  {
    title: 'G番号',
    align: 'start',
    render: (value: HospitalEntity) => value.group?.name ?? '',
  },
  {
    title: '施設番号',
    dataIndex: 'facilityNumber',
    key: 'facilityNumber',
    align: 'start',
  },
  {
    title: '病院コード',
    dataIndex: 'hospitalNo',
    key: 'hospitalNo',
    align: 'start',
  },
  {
    title: '病院名',
    dataIndex: 'name',
    key: 'name',
    align: 'start',
  },
  {
    title: '病院ふりがな',
    dataIndex: 'kana',
    key: 'kana',
    align: 'start',
  },
  {
    title: '郵便番号',
    dataIndex: 'zipCode',
    key: 'zipCode',
    align: 'start',
  },
  {
    title: '住所',
    dataIndex: 'address',
    key: 'address',
    align: 'start',
  },
  {
    title: '電話番号',
    dataIndex: 'tel',
    key: 'tel',
    align: 'start',
  },
  {
    title: '2期',
    align: 'end',
    render: (value: HospitalEntity) => value.capacity?.secondCount ?? '0',
  },
  {
    title: '3期',
    align: 'end',
    render: (value: HospitalEntity) => value.capacity?.thirdCount ?? '0',
  },
  {
    title: '4期',
    align: 'end',
    render: (value: HospitalEntity) => value.capacity?.fourthCount ?? '0',
  },
  {
    title: '年間',
    align: 'end',
    render: (value: HospitalEntity) =>
      (value.capacity?.secondCount ?? 0) +
      (value.capacity?.thirdCount ?? 0) +
      (value.capacity?.fourthCount ?? 0),
  },
  {
    title: '備考',
    align: 'start',
    render: (value: HospitalEntity) => value.capacity?.note ?? '',
  },
  {
    title: '院長',
    dataIndex: 'director',
    key: 'director',
    align: 'start',
  },
  {
    title: (
      <>
        協薬局長
        <br />
        薬剤部科長名
      </>
    ),
    dataIndex: 'headPharmacyDepartment',
    key: 'headPharmacyDepartment',
    align: 'start',
  },
  {
    title: 'メールアドレス',
    dataIndex: 'email',
    key: 'email',
    align: 'start',
  },
  {
    title: '協力病院',
    dataIndex: 'cooperatingHospital',
    key: 'cooperatingHospital',
    align: 'start',
  },
  {
    title: '更新日',
    dataIndex: 'updatedAt',
    key: 'updatedAt;',
    align: 'start',
  },
];

type Props = {
  hospitals: HospitalEntity[];
  page: number;
  total: number;
  onPage: (size: number) => void;
};
export const HospitalTable: FC<Props> = memo((props) => {
  return (
    <Table
      columns={columns}
      dataSource={props.hospitals}
      rowKey={'id'}
      pagination={{
        total: props.total,
        current: props.page,
        pageSize: 50,
        onChange: props.onPage,
        showSizeChanger: false,
      }}
      scroll={{ x: 'calc(100vw - 64px)' }}
    />
  );
});
