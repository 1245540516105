import { axiosInstance } from 'src/providers/axios.provider';
import {
  HospitalAddPharmacyGroupReq,
  HospitalCollectReq,
  HospitalExportCollectReq,
  HospitalExportExcelReq,
  HospitalGetEntryReq,
  HospitalGroupReq,
  HospitalListReq,
  HospitalPatchReq,
  HospitalPharmacyReq,
  HospitalPostEntryReq,
  HospitalPostReq,
} from 'src/service/req/hospital';
import {
  HospitalCollectRes,
  HospitalGetEntryRes,
  HospitalGetRes,
  HospitalGroupRes,
  HospitalListRes,
  HospitalPharmacyRes,
} from 'src/service/res/hospital';
import { AxiosResponse } from 'axios';
const path = '/hospital';
export const hospitalRepository = {
  list: async (req: HospitalListReq) => {
    return await axiosInstance.get<
      HospitalListReq,
      AxiosResponse<HospitalListRes>
    >(`${path}`, {
      params: req,
    });
  },
  group: async (req: HospitalGroupReq) => {
    return await axiosInstance.get<
      HospitalGroupReq,
      AxiosResponse<HospitalGroupRes>
    >(`${path}/group`, { params: req });
  },
  get: async (id: string, scheduleId: number) => {
    return await axiosInstance.get<void, AxiosResponse<HospitalGetRes>>(
      `${path}/${id}`,
      {
        params: {
          scheduleId,
        },
      },
    );
  },
  post: async (req: HospitalPostReq) => {
    return await axiosInstance.post<HospitalPostReq, AxiosResponse<void>>(
      `${path}`,
      req,
    );
  },
  patch: async (req: HospitalPatchReq) => {
    return await axiosInstance.patch<HospitalPatchReq, AxiosResponse<void>>(
      `${path}`,
      req,
    );
  },
  getEntry: async (req: HospitalGetEntryReq) => {
    return await axiosInstance.get<
      HospitalGetEntryReq,
      AxiosResponse<HospitalGetEntryRes>
    >(`${path}/entry`, { params: req });
  },
  postEntry: async (req: HospitalPostEntryReq) => {
    return await axiosInstance.post<HospitalPostEntryReq, AxiosResponse>(
      `${path}/entry`,
      req,
    );
  },
  collect: async (req: HospitalCollectReq) => {
    return await axiosInstance.get<
      HospitalCollectReq,
      AxiosResponse<HospitalCollectRes>
    >(`${path}/collect`, { params: req });
  },
  exportCollect: async (req: HospitalExportCollectReq) => {
    return await axiosInstance.get<HospitalExportCollectReq, AxiosResponse>(
      `${path}/export/collect`,
      { params: req, responseType: 'arraybuffer' },
    );
  },
  pharmacy: async (req: HospitalPharmacyReq) => {
    return await axiosInstance.get<
      HospitalPharmacyReq,
      AxiosResponse<HospitalPharmacyRes>
    >(`${path}/pharmacy`, { params: req });
  },
  addPharmacyGroup: async (req: HospitalAddPharmacyGroupReq) => {
    return await axiosInstance.get<
      HospitalAddPharmacyGroupReq,
      AxiosResponse<HospitalPharmacyRes>
    >(`${path}/add/pharmacy/group`, { params: req });
  },
  exportExcel: async (req: HospitalExportExcelReq) => {
    return await axiosInstance.get<HospitalExportExcelReq, AxiosResponse>(
      `${path}/export/excel`,
      { params: req, responseType: 'arraybuffer' },
    );
  },
};
