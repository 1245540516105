import { axiosInstance } from 'src/providers/axios.provider';
import { SchedulePatchReq, SchedulePostReq } from 'src/service/req/schedule';
import { AxiosResponse } from 'axios';
import { ScheduleDetailRes, ScheduleListRes } from 'src/service/res/schedule';
const path = '/schedule';
export const scheduleRepository = {
  create: async (req: SchedulePostReq) => {
    return await axiosInstance.post<SchedulePostReq, AxiosResponse>(
      `${path}`,
      req,
    );
  },
  update: async (req: SchedulePatchReq) => {
    return await axiosInstance.patch<SchedulePatchReq, AxiosResponse>(
      `${path}`,
      req,
    );
  },
  detail: async () => {
    return await axiosInstance.get<void, AxiosResponse<ScheduleDetailRes>>(
      `${path}/detail`,
    );
  },
  list: async () => {
    return await axiosInstance.get<void, AxiosResponse<ScheduleListRes>>(
      `${path}`,
    );
  },
};
