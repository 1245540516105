import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Table, TableColumnsType, Typography } from 'antd';
import { UniversityEntity } from 'src/domain/university.entity';
import { HospitalCollectEntity } from 'src/domain/hospital.collect.entity';
import { ColumnType } from 'antd/lib/table';
const columns: TableColumnsType<HospitalCollectEntity> = [
  {
    title: '病院名',
    width: 280,
    fixed: 'left',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '病院コード',
    width: 100,
    fixed: 'left',
    dataIndex: 'hospitalNo',
    key: 'hospitalNo',
  },
  {
    title: 'グループ名',
    width: 130,
    fixed: 'left',
    dataIndex: 'groupName',
    key: 'groupName',
  },
];

type Props = {
  universities: UniversityEntity[];
  entries: HospitalCollectEntity[];
  summery: HospitalCollectEntity;
};
export const HospitalCollectTable: FC<Props> = memo((props) => {
  const [cols, setCols] = useState(columns);
  const createColumn = useCallback(
    (period: number) => {
      const func = (value: HospitalCollectEntity) => {
        let color = '#55B7A5B2';
        switch (period) {
          case 2:
            if (value.secondCount[0] < value.secondCount[-1]) {
              color = '#FFC777';
            }
            if (value.secondCount[0] > value.secondCount[-1]) {
              color = '#FF7AA2B2';
            }
            break;
          case 3:
            if (value.thirdCount[0] < value.thirdCount[-1]) {
              color = '#FFC777';
            }
            if (value.thirdCount[0] > value.thirdCount[-1]) {
              color = '#FF7AA2B2';
            }
            break;
          case 4:
            if (value.fourthCount[0] < value.fourthCount[-1]) {
              color = '#FFC777';
            }
            if (value.fourthCount[0] > value.fourthCount[-1]) {
              color = '#FF7AA2B2';
            }
            break;
        }
        return {
          style: { background: color },
        };
      };
      const results: ColumnType<HospitalCollectEntity>[] = [
        {
          title: '定員',
          width: 60,
          align: 'end',
          render: (value: HospitalCollectEntity) =>
            period === 2
              ? value.secondCount[-1]
              : period === 3
                ? value.thirdCount[-1]
                : value.fourthCount[-1],
          onCell: (value: HospitalCollectEntity) => func(value),
        },
        {
          title: '全校計',
          width: 60,
          align: 'end',
          render: (value: HospitalCollectEntity) =>
            period === 2
              ? value.secondCount[0]
              : period === 3
                ? value.thirdCount[0]
                : value.fourthCount[0],
          onCell: (value: HospitalCollectEntity) => func(value),
        },
      ];
      return [
        ...results,
        ...props.universities.map((u) => {
          return {
            title: u.universityOmissionName,
            width: 60,
            align: 'end',
            render: (value: HospitalCollectEntity) =>
              period === 2
                ? value.secondCount[u.id] ?? 0
                : period === 3
                  ? value.thirdCount[u.id] ?? 0
                  : value.fourthCount[u.id] ?? 0,
            onCell: (value: HospitalCollectEntity) => func(value),
          } as ColumnType<HospitalCollectEntity>;
        }),
      ];
    },
    [props.universities],
  );
  useEffect(() => {
    if (cols.length === columns.length + props.universities.length * 3) return;
    const c = [...columns];
    c.push({
      title: '2期',
      key: 'secondCount',
      dataIndex: 'secondCount',
      children: createColumn(2),
    });
    c.push({
      title: '3期',
      key: 'thirdCount',
      dataIndex: 'thirdCount',
      children: createColumn(3),
    });
    c.push({
      title: '4期',
      key: 'fourthCount',
      dataIndex: 'fourthCount',
      children: createColumn(4),
    });

    setCols([...c]);
  }, [props.universities, cols, createColumn]);
  return (
    <Table
      columns={cols}
      bordered
      size={'small'}
      pagination={false}
      dataSource={props.entries}
      rowKey={'id'}
      scroll={{ x: 'calc(100vw - 64px)', y: '600px' }}
      summary={() => {
        return (
          <>
            <Table.Summary.Row style={{ background: '#DDDDDD' }}>
              <Table.Summary.Cell index={0} colSpan={3}>
                <Typography.Text strong={true}>エントリー総数</Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {' '}
                  {props.summery.secondCount[-1]}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {' '}
                  {props.summery.secondCount[0]}
                </Typography.Text>
              </Table.Summary.Cell>
              {props.universities.map((u) => {
                return (
                  <Table.Summary.Cell index={0} key={u.id} align={'end'}>
                    <Typography.Text strong={true}>
                      {' '}
                      {props.summery.secondCount[u.id] ?? 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {' '}
                  {props.summery.thirdCount[-1]}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {' '}
                  {props.summery.thirdCount[0]}
                </Typography.Text>
              </Table.Summary.Cell>
              {props.universities.map((u) => {
                return (
                  <Table.Summary.Cell index={0} key={u.id} align={'end'}>
                    <Typography.Text strong={true}>
                      {' '}
                      {props.summery.thirdCount[u.id] ?? 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                );
              })}
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {' '}
                  {props.summery.fourthCount[-1]}
                </Typography.Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={0} align={'end'}>
                <Typography.Text strong={true}>
                  {' '}
                  {props.summery.fourthCount[0]}
                </Typography.Text>
              </Table.Summary.Cell>
              {props.universities.map((u) => {
                return (
                  <Table.Summary.Cell index={0} key={u.id} align={'end'}>
                    <Typography.Text strong={true}>
                      {' '}
                      {props.summery.fourthCount[u.id] ?? 0}
                    </Typography.Text>
                  </Table.Summary.Cell>
                );
              })}
            </Table.Summary.Row>
          </>
        );
      }}
    />
  );
});
