import { FC, memo, useContext } from 'react';
import { Flex, Tag, Typography, Layout } from 'antd';
import { Menus } from 'src/components/Organisms/Common/Menus';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { ScheduleName } from 'src/domain/schedule.entity';

type Props = {
  isSelect: number;
};
export const Header: FC<Props> = memo((props) => {
  const { schedule, logout, user } = useContext(ScheduleContext);
  return (
    <Layout.Header
      style={{
        width: '100%',
        border: '1px solid rgba(0, 0, 0, 0.70))',
        padding: 0,
        height: 'fit-content',
        background: '#FFFFFF',
      }}
    >
      <Flex
        vertical={false}
        justify={'space-between'}
        align={'center'}
        style={{
          background: '#000000',
          width: '100%',
          height: '96px',
          padding: '0 32px',
        }}
      >
        <Flex vertical={false} gap={'large'} align={'center'}>
          <Typography.Title level={4} style={{ margin: 0, color: '#FFFFFF' }}>
            一般社団法人 薬学教育協議会 <br />
            病院・薬局実務実習 近畿地区調整機構
          </Typography.Title>
          <Tag
            color="#FFFFFF"
            style={{ color: '#000000', padding: '8px', fontWeight: 'bold' }}
          >
            {schedule && ScheduleName[schedule.statusId]}
          </Tag>
        </Flex>
        <Flex vertical={false} gap={'middle'}>
          <Typography.Link
            href={'https://kinki-chosei.net/'}
            style={{ color: '#FFFFFF' }}
          >
            調整機構HP
          </Typography.Link>
          <Typography.Link style={{ color: '#FFFFFF' }} onClick={logout}>
            ログアウト
          </Typography.Link>
        </Flex>
      </Flex>
      {user && <Menus select={props.isSelect} auth={user?.authId} />}
    </Layout.Header>
  );
});
