import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import { masterRepository } from 'src/service/master.repository';
import { PrefectureEntity } from 'src/domain/prefecture.entity';
import { InfoContext } from 'src/providers/info.provider';

export const usePharmacyMasterSelectHooks = () => {
  const navigate = useNavigate();
  const onClick = useCallback((path: number) => {
    navigate(`/pharmacy/master/upload/${path}`);
  }, []);
  const [pharmacyAssociation, setAssociation] = useState<PrefectureEntity[]>(
    [],
  );
  const { addLoading, removeLoading } = useContext(InfoContext);
  useEffect(() => {
    addLoading();
    masterRepository
      .prefecture()
      .then((data) => setAssociation(data.data.results))
      .catch(() => null)
      .finally(removeLoading);
  }, []);
  return { onClick, pharmacyAssociation };
};
