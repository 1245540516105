import { FC, memo, useState } from 'react';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { EntryEntity } from 'src/domain/entry.entity';
import { PharmacyEntryRow } from 'src/components/Organisms/Pharmacy/PharmacyEntryRow';
import { HospitalPharmacy } from 'src/domain/hospital.entity';
type Props = {
  group: HospitalPharmacy;
  entry: { [key: number]: EntryEntity };
  sumEntry: { [key: number]: EntryEntity };
  onChange: (pharmacyId: number, period: string, num: number) => void;
  disabled: boolean;
};

export const PharmacyEntryList: FC<Props> = memo((props) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <Flex vertical={true} style={{ alignSelf: 'stretch' }} align={'flex-start'}>
      <Flex
        vertical={false}
        align={'center'}
        justify={'space-between'}
        style={{
          height: '48px',
          padding: '0 16px',
          background: '#000000',
          alignSelf: 'stretch',
        }}
      >
        <Typography.Title
          level={3}
          style={{
            color: '#FFFFFF',
            margin: 0,
            padding: 0,
          }}
        >
          {/*{props.group.area?.concat(props.group.group)} &nbsp;-&nbsp; */}
          {props.group.area} &nbsp;-&nbsp;
          {props.group.group} &nbsp;-&nbsp;
          {props.group.name}
        </Typography.Title>
        {!isOpen && (
          <DownOutlined
            style={{ color: '#FFFFFF', fontSize: '24px' }}
            onClick={() => setOpen(true)}
          />
        )}
        {isOpen && (
          <UpOutlined
            style={{ color: '#FFFFFF', fontSize: '24px' }}
            onClick={() => setOpen(false)}
          />
        )}
      </Flex>

      <Flex
        vertical={false}
        align={'flex-start'}
        justify={'flex-start'}
        style={{
          borderLeft: '1px solid #000000',
          display: isOpen ? 'inherit' : 'none',
          alignSelf: 'stretch',
        }}
      >
        <ColumnStyle
          vertical={true}
          justify={'center'}
          align={'center'}
          style={{
            alignSelf: 'stretch',
            minWidth: '280px',
            flex: '1 0 0',
          }}
        >
          薬局コード
          <br />
          薬局名
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'} align={'center'}>
          備考
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'} align={'center'}>
          項目
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'} align={'center'}>
          通年
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'} align={'center'}>
          第1期
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'} align={'center'}>
          第2期
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'} align={'center'}>
          第3期
        </ColumnStyle>
      </Flex>
      {props.group.pharmacy?.map((p, i) => (
        <PharmacyEntryRow
          key={i}
          pharmacy={p}
          entry={props.entry?.[p.id]}
          sumEntry={props.sumEntry?.[p.id]}
          isOpen={isOpen}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      ))}
    </Flex>
  );
});

const ColumnStyle = styled(Flex)`
  background: #000000;
  color: #ffffff;
  width: 160px;
  padding: 8px;
  border: 1px solid #ffffff;
  align-self: stretch;
`;
