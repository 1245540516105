import { FC, memo, ReactNode } from 'react';
import { Flex } from 'antd';
import { Menu } from 'src/components/Molecules';

type Props = {
  isOpen: boolean;
  items: {
    name: ReactNode;
    link?: string;
  }[];
};
export const SubMenus: FC<Props> = memo((props) => {
  return (
    <Flex
      vertical={false}
      style={{
        display: props.isOpen ? 'initial' : 'none',
        position: 'absolute',
        top: '60px',
      }}
    >
      {props.items.map((item, i) => (
        <Menu key={i} name={item.name} isSelect={false} link={item.link} />
      ))}
    </Flex>
  );
});
