import { Dayjs } from 'dayjs';

export type ScheduleEntity = {
  id: number;
  name: string;
  statusId: Status;
  hospitalEntry: DateBetweenEntity;
  hospitalAdjustment: DateBetweenEntity;
  hospitalReentry: DateBetweenEntity;
  pharmacyMaster: DateBetweenEntity;
  pharmacyEntry: DateBetweenEntity;
  pharmacyAdjustment: DateBetweenEntity;
};

export type DateBetweenEntity = Dayjs[];

export enum Status {
  HOSPITAL_MASTER = 1,
  HOSPITAL_ENTRY,
  HOSPITAL_ADJUSTMENT,
  HOSPITAL_REENTRY,
  PHARMACY_MASTER,
  PHARMACY_ENTRY,
  PHARMACY_ADJUSTMENT,
  COMPETE,
}

export const ScheduleName: { [key in Status]: string } = {
  [Status.HOSPITAL_MASTER]: `病院マスター登録`,
  [Status.HOSPITAL_ENTRY]: `病院エントリー`,
  [Status.HOSPITAL_ADJUSTMENT]: `病院エントリー数調整`,
  [Status.HOSPITAL_REENTRY]: `病院再エントリー`,
  [Status.PHARMACY_MASTER]: `薬局マスターメンテナンス`,
  [Status.PHARMACY_ENTRY]: `薬局エントリー`,
  [Status.PHARMACY_ADJUSTMENT]: `薬局エントリー調整`,
  [Status.COMPETE]: `完了`,
};
