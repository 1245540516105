import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Button, Flex } from 'antd';
import styled from 'styled-components';
import { usePharmacyMasterSelectHooks } from 'src/hooks/pages/use.pharmacy.master.select.hooks';

export const PharmacyMasterSelect: FC = memo(() => {
  const { pharmacyAssociation, onClick } = usePharmacyMasterSelectHooks();
  return (
    <Default
      title={'薬局マスターメンテナンス画面'}
      isSelect={5}
      contents={'マスター情報のメンテナンスを行う薬剤師会を選んでください'}
    >
      <RootStyle vertical={true} gap={16}>
        {pharmacyAssociation.map((pharmacyAssociation) => (
          <Button
            type={'primary'}
            style={{ width: '100%' }}
            key={pharmacyAssociation.id}
            onClick={() => onClick(pharmacyAssociation.id)}
          >
            {pharmacyAssociation.name} 薬剤師会
          </Button>
        ))}
      </RootStyle>
    </Default>
  );
});

const RootStyle = styled(Flex)`
  border: 1px solid #000000;
  padding: 16px;
  align-self: stretch;
`;
