import { useCallback, useContext, useEffect, useState } from 'react';
import { HospitalEntity } from 'src/domain/hospital.entity';
import { masterRepository } from 'src/service/master.repository';
import { AreaEntity } from 'src/domain/area.entity';
import { GroupEntity } from 'src/domain/group.entity';
import { useNavigate, useParams } from 'react-router-dom';
import { hospitalRepository } from 'src/service/hospital.repository';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { InfoContext } from 'src/providers/info.provider';

export const useHospitalMasterEditHooks = () => {
  const [hospital, setHospital] = useState<HospitalEntity>();
  const [isConfirm, setConfirm] = useState(false);
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [groups, setGroups] = useState<GroupEntity[]>([]);
  const { schedule } = useContext(ScheduleContext);
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  const onConfirm = useCallback((values: HospitalEntity) => {
    setHospital({ ...values, id: Number(params.id) });
    setConfirm(true);
  }, []);
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id && schedule) {
      addLoading();
      hospitalRepository
        .get(params.id, schedule.id)
        .then((data) => setHospital(data.data))
        .catch(() => null)
        .finally(removeLoading);
    }
  }, [params.id, schedule]);

  const onUpdate = useCallback(async () => {
    if (!hospital) return;
    addLoading();
    await hospitalRepository
      .patch({
        ...hospital,
        capacity: { ...hospital.capacity, scheduleId: schedule?.id },
      })
      .then(() => {
        createMessage('病院マスターを変更しました。', 'info');
        navigate('/hospital/master');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [hospital]);

  const onBack = useCallback(() => {
    setConfirm(false);
  }, []);
  useEffect(() => {
    addLoading();
    Promise.all([
      masterRepository.area().then((data) => setAreas(data.data.results)),
      masterRepository.group({}).then((data) => setGroups(data.data.results)),
    ])
      .catch(() => null)
      .finally(removeLoading);
  }, []);

  return {
    hospital,
    isConfirm,
    onConfirm,
    onUpdate,
    onBack,
    areas,
    groups,
  };
};
