import { FC, memo } from 'react';
import { Flex, Form, Input } from 'antd';
import { LoginEntity } from 'src/domain/login.entity';
import { SubmitButton } from 'src/components/Atoms/SubmitButton';
import { Link } from 'react-router-dom';
type Props = {
  onLogin: (value: LoginEntity) => void;
};

export const LoginForm: FC<Props> = memo((props) => {
  const [form] = Form.useForm<LoginEntity>();
  return (
    <Form form={form} layout="vertical" onFinish={props.onLogin}>
      <Flex vertical={true} align={'center'}>
        <Form.Item
          label={'ログインID'}
          required={true}
          style={{ width: '280px' }}
          name={'accountId'}
          rules={[{ required: true, message: '入力してください' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={'パスワード'}
          required={true}
          style={{ width: '280px' }}
          name={'password'}
          rules={[
            { required: true, message: '入力してください' },
            { min: 6, message: '最低6文字以上入力してください' },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Flex>
      <Flex justify={'center'} style={{ width: '100%', margin: '16px' }}>
        <SubmitButton form={form}>ログイン</SubmitButton>
      </Flex>
      <Link to={'https://kinki-chosei.net/'}>
        パスワードをお忘れの場合やログインに関するお問い合わせは事務局へ
      </Link>
    </Form>
  );
});
