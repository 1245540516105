import { useCallback, useContext, useEffect, useState } from 'react';
import { HospitalEntity } from 'src/domain/hospital.entity';
import { AccountCreateEntity } from 'src/domain/account.entity';
import { AuthType } from 'src/common/types';
import { AreaEntity } from 'src/domain/area.entity';
import { masterRepository } from 'src/service/master.repository';
import { GroupEntity } from 'src/domain/group.entity';
import { hospitalRepository } from 'src/service/hospital.repository';
import { useNavigate } from 'react-router-dom';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { InfoContext } from 'src/providers/info.provider';

export const useHospitalMasterCreateHooks = () => {
  const { schedule } = useContext(ScheduleContext);
  const [hospital, setHospital] = useState<HospitalEntity>({
    id: 0,
    capacity: {
      scheduleId: schedule?.id,
      secondCount: 0,
      thirdCount: 0,
      fourthCount: 0,
    },
    hospitalNo: '',
    name: '',
    isCooperatingHospital: false,
    address: '',
    departmentName: '',
    director: '',
    email: '',
    facilityNumber: '',
    headPharmacyDepartment: '',
    kana: '',
    zipCode: '',
    tel: '',
  });
  const [account, setAccount] = useState<AccountCreateEntity>({
    authId: AuthType.Hospital,
    accountId: '',
    password: '',
  });
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [groups, setGroups] = useState<GroupEntity[]>([]);
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  const onConfirm = useCallback((values: HospitalEntity) => {
    setHospital(values);
    setStep(2);
  }, []);

  const onCreate = useCallback(async () => {
    addLoading();
    await hospitalRepository
      .post({
        account,
        hospital: {
          ...hospital,
          capacity: { ...hospital.capacity, scheduleId: schedule?.id },
        },
      })
      .then(() => {
        createMessage('病院が登録されました。', 'info');
        navigate('/accounts');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [hospital, account, schedule]);

  const onBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const onNext = useCallback((values: AccountCreateEntity) => {
    setAccount(values);
    setStep(1);
  }, []);

  useEffect(() => {
    addLoading();
    masterRepository
      .area()
      .then((data) => setAreas(data.data.results))
      .finally(removeLoading);
  }, []);
  const setArea = useCallback(async (areaId?: number) => {
    if (!areaId) {
      setGroups([]);
      return;
    }
    addLoading();
    await masterRepository
      .group({
        areaId,
      })
      .then((data) => setGroups(data.data.results))
      .finally(removeLoading);
  }, []);
  return {
    hospital,
    step,
    onConfirm,
    onCreate,
    onBack,
    account,
    onNext,
    areas,
    setArea,
    groups,
  };
};
