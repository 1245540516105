import { FC, memo, useCallback } from 'react';
import { Flex, Input } from 'antd';
import styled from 'styled-components';
type Props = {
  capacity: number;
  entry?: number;
  sumEntry: number;
  period: string;
  disabled: boolean;
  pharmacyId: number;
  onChange?: (pharmacyId: number, period: string, num: number) => void;
};
export const PharmacyEntryCell: FC<Props> = memo((props) => {
  const onChange = useCallback(
    (period: string, num: string) => {
      props.onChange &&
        props.onChange(
          props.pharmacyId,
          period,
          isNaN(Number(num)) ? 0 : Number(num),
        );
    },
    [props.pharmacyId, props.onChange],
  );
  return (
    <Flex vertical={true} style={{ width: '160px' }}>
      <CellHeaderStyle
        vertical={false}
        justify={'flex-end'}
        align={'center'}
        style={{ padding: '4px 28px' }}
      >
        {props.capacity}
      </CellHeaderStyle>
      {props.period !== 'all' && (
        <CellEditStyle
          vertical={false}
          justify={'flex-end'}
          $isError={props.capacity < (props.entry ?? 0) + props.sumEntry}
        >
          <Input
            value={props.entry ?? 0}
            type={'number'}
            min={0}
            disabled={props.disabled}
            onChange={(e) => onChange(props.period, e.target.value)}
          />
        </CellEditStyle>
      )}
      {props.period === 'all' && (
        <CellHeaderStyle
          justify={'flex-end'}
          align={'center'}
          style={{
            background: '#FFFFFF',
            color: '#000000',
            padding: '4px 28px',
          }}
        >
          {props.entry ?? 0}
        </CellHeaderStyle>
      )}
      <CellHeaderStyle
        vertical={false}
        justify={'flex-end'}
        align={'center'}
        style={{ padding: '4px 28px' }}
      >
        {props.sumEntry}
      </CellHeaderStyle>
    </Flex>
  );
});

const CellStyle = styled(Flex)`
  border: 1px solid #000000;
  height: 39px;
`;

const CellHeaderStyle = styled(CellStyle)`
  background: #000000;
  color: #ffffff;
  border-color: #ffffff;
`;

const CellEditStyle = styled(CellStyle)<{ $isError: boolean }>`
  height: 39px;
  input {
    text-align: end;
    background: ${(props) => (props.$isError ? '#FF0000' : '#fff385')};
    &:disabled {
      background: ${(props) => (props.$isError ? '#FF0000' : undefined)};
    }
  }
`;
