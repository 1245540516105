import React, { FC, memo } from 'react';
import { NewsEntity } from 'src/domain/news.entity';
import { ColumnsType } from 'antd/es/table/interface';
import { Button, Popconfirm, Table } from 'antd';
import { dateFormat } from 'src/common/format';

type Props = {
  news: NewsEntity[];
  onChange: (id: number) => void;
  onStop: (id: NewsEntity) => void;
  page: number;
  total: number;
  onPage: (size: number) => void;
};
export const NewsTable: FC<Props> = memo((props) => {
  const columns: ColumnsType<NewsEntity> = [
    {
      title: 'お知らせ日',
      align: 'center',
      render: (value: NewsEntity) => <div>{value.date.format(dateFormat)}</div>,
    },
    {
      title: '内容',
      dataIndex: 'content',
      key: 'content',
      align: 'start',
    },
    {
      title: '変更',
      align: 'center',
      render: (value: NewsEntity) => (
        <Button type={'primary'} onClick={() => props.onChange(value.id)}>
          変更
        </Button>
      ),
    },
    {
      title: '停止/再開',
      align: 'center',
      render: (value: NewsEntity) => (
        <Popconfirm
          title={value.invalid === 1 ? '再開' : '停止'}
          description={
            value.invalid === 1 ? '再開しますか？' : '停止しますか？'
          }
          okText={value.invalid === 1 ? '再開' : '停止'}
          cancelText="キャンセル"
          onConfirm={() => props.onStop(value)}
        >
          <Button type={'primary'}>
            {value.invalid === 1 ? '再開' : '停止'}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rowKey={'id'}
      pagination={{
        total: props.total,
        current: props.page,
        pageSize: 50,
        onChange: props.onPage,
        showSizeChanger: false,
      }}
      dataSource={props.news}
    />
  );
});
