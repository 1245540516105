import { useCallback, useContext, useState } from 'react';
import { ScheduleEntity } from 'src/domain/schedule.entity';
import { scheduleRepository } from 'src/service/schedule.repository';
import { useNavigate } from 'react-router-dom';
import { InfoContext } from 'src/providers/info.provider';

export const useScheduleCreateHooks = () => {
  const [schedule, setSchedule] = useState<ScheduleEntity>({
    id: 0,
    name: '',
    statusId: 0,
    hospitalEntry: [],
    hospitalAdjustment: [],
    hospitalReentry: [],
    pharmacyMaster: [],
    pharmacyEntry: [],
    pharmacyAdjustment: [],
  });

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);

  const onNext = useCallback((values: ScheduleEntity) => {
    setSchedule(values);
    setStep(1);
  }, []);
  const onBack = useCallback(() => {
    setStep(0);
  }, []);

  const onCreate = useCallback(async () => {
    addLoading();
    await scheduleRepository
      .create({
        name: schedule.name,
        hospitalEntry: schedule.hospitalEntry.map((d) => d.toDate()),
        hospitalAdjustment: schedule.hospitalAdjustment.map((d) => d.toDate()),
        hospitalReentry: schedule.hospitalReentry.map((d) => d.toDate()),
        pharmacyMaster: schedule.pharmacyMaster.map((d) => d.toDate()),
        pharmacyEntry: schedule.pharmacyEntry.map((d) => d.toDate()),
        pharmacyAdjustment: schedule.pharmacyAdjustment.map((d) => d.toDate()),
      })
      .then(() => {
        createMessage(`スケジュールを登録しました。`, 'info');
        navigate('/schedule');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [schedule]);

  return { schedule, onNext, onBack, step, onCreate };
};
