import { FC, memo } from 'react';
import { DateBetweenEntity } from 'src/domain/schedule.entity';
import { Flex, Select } from 'antd';
import { dateTimeFormat } from 'src/common/format';
import { UniversityEntity } from 'src/domain/university.entity';
import { AuthType } from 'src/common/types';

type Props = {
  between?: DateBetweenEntity;
  between2?: DateBetweenEntity;
  universities: UniversityEntity[];
  authId: AuthType;
  onSelect: (universityId: number) => void;
};
export const EntryHeader: FC<Props> = memo((props) => {
  return (
    <Flex
      vertical={false}
      align={'center'}
      justify={'space-between'}
      style={{ alignSelf: 'stretch' }}
    >
      <Flex vertical={true}>
        <Flex vertical={false} align={'start'}>
          エントリー期間：
          {props.between?.[0] && props.between?.[1] && (
            <Flex vertical={false} align={'start'} gap={8}>
              <div>{props.between[0].format(dateTimeFormat)}</div>
              <div>~</div>
              <div>{props.between[1].format(dateTimeFormat)}</div>
            </Flex>
          )}
          {!props.between && '現在エントリー期間がありません。'}
        </Flex>
        {props.between2 && (
          <Flex vertical={false} align={'start'}>
            再エントリー期間：
            {props.between2?.[0] && props.between2?.[1] && (
              <Flex vertical={false} align={'start'} gap={8}>
                <div>{props.between2[0].format(dateTimeFormat)}</div>
                <div>~</div>
                <div>{props.between2[1].format(dateTimeFormat)}</div>
              </Flex>
            )}
            {!props.between2 && '現在エントリー期間がありません。'}
          </Flex>
        )}
      </Flex>
      <></>
      {props.authId === AuthType.Admin && (
        <Select placeholder={'大学選択　　'} onChange={props.onSelect}>
          {props.universities.map((university) => (
            <Select.Option key={university.id} value={university.id}>
              {university.name}
            </Select.Option>
          ))}
        </Select>
      )}
    </Flex>
  );
});
