import { FC, memo, useEffect, useState } from 'react';
import { Button, Checkbox, Flex, Form, Select } from 'antd';
import { ScheduleEntity } from 'src/domain/schedule.entity';
import { AreaEntity } from 'src/domain/area.entity';
import { SearchEntity } from 'src/domain/search.entity';
import { groupBy } from 'src/common/group.by';

type Props = {
  schedules: ScheduleEntity[];
  areas: AreaEntity[];
  onSearch: (values: SearchEntity) => void;
  onDownload: (values: SearchEntity) => void;
};
export const HospitalCollectSearchBox: FC<Props> = memo((props) => {
  const [form] = Form.useForm<SearchEntity>();
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState<boolean>(false);
  const allCheck = (checked: boolean) => {
    form.setFieldValue(
      'areaId',
      checked ? props.areas.map((area) => area.id) : [],
    );
  };
  useEffect(() => {
    console.log('hospital search');
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);
  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ areaId: [] }}
      onFinish={props.onSearch}
    >
      <Flex vertical={true} align={'flex-start'} gap={'middle'}>
        <Form.Item
          label="スケジュール名"
          required={true}
          name={'scheduleId'}
          rules={[{ required: true, message: '入力してください' }]}
        >
          <Select placeholder={'スケジュール名'}>
            {props.schedules.map((schedule) => (
              <Select.Option key={schedule.id} value={schedule.id}>
                {schedule.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Flex
          vertical={true}
          style={{ border: '1px solid #000000', padding: '16px' }}
        >
          <Flex vertical={false}>
            <Checkbox onChange={(e) => allCheck(e.target.checked)}>
              全地区チェック
            </Checkbox>
          </Flex>
          <Form.Item
            required={true}
            name={'areaId'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Checkbox.Group>
              <Flex vertical={true}>
                {Object.entries(
                  groupBy<number, AreaEntity>(props.areas, (a) => a.type),
                ).map(([key, list]) => (
                  <Flex key={key} vertical={false}>
                    {list?.map((area) => (
                      <Checkbox key={area.id} value={area.id}>
                        {area.name}
                      </Checkbox>
                    ))}
                  </Flex>
                ))}
              </Flex>
            </Checkbox.Group>
          </Form.Item>
        </Flex>
      </Flex>
      <Flex
        vertical={false}
        align={'center'}
        justify={'center'}
        gap={'middle'}
        style={{
          width: '100%',
          margin: '16px',
        }}
      >
        <Button type={'primary'} disabled={!submittable} htmlType={'submit'}>
          上記の検索条件で集計結果を表示
        </Button>
        <Button
          type={'primary'}
          disabled={!submittable}
          onClick={() => props.onDownload(form.getFieldsValue())}
        >
          上記の検索条件で集計結果をダウンロード
        </Button>
      </Flex>
    </Form>
  );
});
