import { useCallback, useContext } from 'react';
import { LoginEntity } from 'src/domain/login.entity';
import { useNavigate } from 'react-router-dom';
import { authRepository } from 'src/service/auth.repository';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { InfoContext } from 'src/providers/info.provider';

export const useLoginHooks = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(ScheduleContext);
  const { addLoading, removeLoading } = useContext(InfoContext);
  const login = useCallback(async (value: LoginEntity) => {
    addLoading();
    await authRepository
      .login(value)
      .then((data) => {
        localStorage.setItem('user', JSON.stringify(data.data));
        setUser(data.data);
        navigate('/');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);

  return { login };
};
