import { FC, memo } from 'react';
import { Flex, Typography } from 'antd';
import { DateBetweenEntity } from 'src/domain/schedule.entity';
import { dateTimeFormat } from 'src/common/format';

type Props = {
  startLabel: string;
  endLabel: string;
  rangeDate: DateBetweenEntity;
};
export const RangeText: FC<Props> = memo((props) => {
  return (
    <Flex vertical={false} gap={8}>
      <Flex vertical={true} gap={8}>
        <Typography.Text style={{ fontSize: '12px' }}>
          {props.startLabel}
        </Typography.Text>
        {props.rangeDate.length > 0 && (
          <div>{props.rangeDate[0].format(dateTimeFormat)}</div>
        )}
      </Flex>
      <Flex vertical={true} gap={8}>
        <Typography.Text style={{ fontSize: '12px' }}>
          {props.endLabel}
        </Typography.Text>
        {props.rangeDate.length > 1 && (
          <div>{props.rangeDate[1].format(dateTimeFormat)}</div>
        )}
      </Flex>
    </Flex>
  );
});
