import { FC, memo, ReactNode } from 'react';
import { Flex, Layout, Typography } from 'antd';
import { Header } from 'src/components/Organisms/Common/Header';

type Props = {
  title: string;
  isSelect: number;
  contents: ReactNode;
  children: ReactNode;
};
export const Default: FC<Props> = memo(
  ({ title, isSelect, contents, children }) => {
    return (
      <Layout color={'#FFFFFF'}>
        <Header isSelect={isSelect} />
        <Layout.Content>
          <Flex
            vertical={true}
            gap={'large'}
            align={'center'}
            justify={'center'}
            style={{
              padding: '64px 32px',
              alignItems: 'center',
              margin: 'auto',
              width: 'fit-content',
            }}
          >
            <Flex vertical={true} align={'center'} justify={'space-between'}>
              <Typography.Title level={3}>{title}</Typography.Title>
              {contents}
            </Flex>
            {children}
          </Flex>
        </Layout.Content>
      </Layout>
    );
  },
);
