import { axiosInstance } from 'src/providers/axios.provider';
import {
  AccountCreateReq,
  AccountDeleteReq,
  AccountListReq,
  AccountUpdateReq,
} from 'src/service/req/account';
import { AxiosResponse } from 'axios';
import { AccountGetRes, AccountListRes } from 'src/service/res/account';
const path = '/account';
export const accountRepository = {
  create: async (req: AccountCreateReq) => {
    return await axiosInstance.post<AccountCreateReq, AxiosResponse>(
      `${path}`,
      req,
    );
  },
  list: async (req: AccountListReq) => {
    return await axiosInstance.get<
      AccountListReq,
      AxiosResponse<AccountListRes>
    >(`${path}`, { params: req });
  },
  get: async (userId: number) => {
    return await axiosInstance.get<void, AxiosResponse<AccountGetRes>>(
      `${path}/${userId}`,
    );
  },
  update: async (req: AccountUpdateReq) => {
    return await axiosInstance.patch<AccountUpdateReq, AxiosResponse>(
      `${path}`,
      req,
    );
  },
  delete: async (req: AccountDeleteReq) => {
    return await axiosInstance.delete<AccountDeleteReq, AxiosResponse>(
      `${path}`,
      { data: req },
    );
  },
};
