import React, { FC, memo } from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { PharmacyEntity } from 'src/domain/pharmacy.entity';
const removeDuplicatesWithFilter = (arr: any[]): any[] => {
  return arr.filter((element, index) => {
    return arr.indexOf(element) === index;
  });
};

const columns: ColumnsType<PharmacyEntity> = [
  {
    title: '地区',
    align: 'start',
    render: (value: PharmacyEntity) => {
      return value.group.area.name;
    },
  },
  {
    title: 'G番号',
    align: 'start',
    render: (value: PharmacyEntity) => {
      return value.group.name;
    },
  },
  {
    title: '病院名',
    align: 'start',
    render: (value: PharmacyEntity) => {
      return (
        <>
          {removeDuplicatesWithFilter(
            value.hospitals.map((hospital) => hospital.name),
          ).join('/')}
        </>
      );
    },
  },
  {
    title: '薬局登録コード',
    dataIndex: 'pharmacyNo',
    key: 'pharmacyNo',
    align: 'start',
  },
  {
    title: '薬局店舗名',
    dataIndex: 'name',
    key: 'name',
    align: 'start',
  },
  {
    title: '薬局ふりがな',
    dataIndex: 'kana',
    key: 'kana',
    align: 'start',
  },
  {
    title: '郵便番号',
    dataIndex: 'zipCode',
    key: 'zipCode',
    align: 'start',
  },
  {
    title: '住所',
    dataIndex: 'address',
    key: 'address',
    align: 'start',
  },
  {
    title: '電話番号',
    dataIndex: 'tel',
    key: 'tel',
    align: 'start',
  },
  {
    title: '管理名',
    dataIndex: 'managerName',
    key: 'managerName',
    align: 'start',
  },
  {
    title: '1期',
    align: 'end',
    render: (value: PharmacyEntity) => value.capacity?.firstCount ?? 0,
  },
  {
    title: '2期',
    align: 'end',
    render: (value: PharmacyEntity) => value.capacity?.secondCount ?? 0,
  },
  {
    title: '3期',
    align: 'end',
    render: (value: PharmacyEntity) => value.capacity?.thirdCount ?? 0,
  },
  {
    title: '年間',
    align: 'end',
    render: (value: PharmacyEntity) => value.capacity?.allCount ?? 0,
  },
  {
    title: '備考',
    align: 'start',
    render: (value: PharmacyEntity) => value.capacity?.note,
  },
  {
    title: '更新日',
    dataIndex: 'updateAt',
    key: 'updateAt;',
    align: 'start',
  },
];

type Props = {
  pharmacies: PharmacyEntity[];
  page: number;
  total: number;
  onPage: (size: number) => void;
};

export const PharmacyTable: FC<Props> = memo((props) => {
  return (
    <Table
      columns={columns}
      dataSource={props.pharmacies}
      rowKey={'id'}
      pagination={{
        total: props.total,
        current: props.page,
        pageSize: 50,
        onChange: props.onPage,
        showSizeChanger: false,
      }}
      scroll={{ x: 'calc(100vw - 64px)' }}
    />
  );
});
