import { FC, memo } from 'react';
import { Button, Flex } from 'antd';

import { Default } from 'src/components/Layouts/default';
import { ScheduleForm } from 'src/components/Organisms/Schedule/ScheduleForm';
import { ScheduleConfirm } from 'src/components/Organisms/Schedule/ScheduleConfirm';
import { SecondaryButton } from 'src/components/Atoms/SecondaryButton';
import { useScheduleEditHooks } from 'src/hooks/pages/use.schedule.edit.hooks';

export const ScheduleEdit: FC = memo(() => {
  const { values, onCreate, step, onNext, onBack } = useScheduleEditHooks();
  return (
    <Default
      title={'エントリースケジュール編集'}
      isSelect={5}
      contents={
        <Flex vertical={true} gap={8}>
          <div style={{ color: 'red' }}>※印は入力必須項目です。</div>
        </Flex>
      }
    >
      {step === 0 && values && (
        <ScheduleForm schedule={values} onConfirm={onNext} />
      )}
      {step === 1 && values && (
        <Flex vertical={true} gap={'large'} align={'center'} justify={'center'}>
          <ScheduleConfirm schedule={values} />
          <Flex
            justify={'center'}
            style={{ width: '100%', margin: '16px' }}
            gap={32}
          >
            <SecondaryButton onClick={onBack}>戻る</SecondaryButton>
            <Button type={'primary'} onClick={onCreate}>
              更新
            </Button>
          </Flex>
        </Flex>
      )}
    </Default>
  );
});
