import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { PharmacyError, PharmacyUpload } from 'src/components/Organisms/Master';
import { usePharmacyMasterUploadHooks } from 'src/hooks/pages/use.pharmacy.master.upload.hooks';
import { Flex } from 'antd';
import { dateTimeFormat } from 'src/common/format';

export const PharmacyMasterUpload: FC = memo(() => {
  const {
    downloadResult,
    downloadMaster,
    uploadMaster,
    uploadResult,
    errors,
    name,
    disabled,
    schedule,
  } = usePharmacyMasterUploadHooks();
  return (
    <Default
      title={'薬局マスター情報メンテナンスおよび調整結果アップロード'}
      isSelect={5}
      contents={''}
    >
      <Flex vertical={false} align={'start'}>
        調査結果アップロード可能期間：
        {schedule?.pharmacyAdjustment?.[0] &&
          schedule?.pharmacyAdjustment?.[1] && (
            <Flex vertical={false} align={'start'} gap={8}>
              <div>
                {schedule?.pharmacyAdjustment[0].format(dateTimeFormat)}
              </div>
              <div>~</div>
              <div>
                {schedule?.pharmacyAdjustment[1].format(dateTimeFormat)}
              </div>
            </Flex>
          )}
        {!schedule?.pharmacyAdjustment &&
          '現在調査結果アップロード期間がありません。'}
      </Flex>
      {name && (
        <PharmacyUpload
          name={name}
          masterDownload={downloadMaster}
          masterUpload={uploadMaster}
          resultDownload={downloadResult}
          resultUpload={uploadResult}
          disabled={disabled}
        />
      )}
      <PharmacyError errors={errors} />
    </Default>
  );
});
