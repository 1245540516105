import { createContext, ReactNode, useCallback, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Spin } from 'antd';
import styled from 'styled-components';

export type InfoContextType = {
  addLoading: () => void;
  removeLoading: () => void;
  createMessage: (
    message: string,
    type: 'success' | 'info' | 'warning' | 'error',
  ) => void;
};

export const InfoContext = createContext<InfoContextType>(
  {} as InfoContextType,
);

export const InfoProvider = (props: { children: ReactNode }) => {
  const [isLoading, setLoading] = useState(0);
  const [message, setMessage] = useState<string>();
  const [type, setType] = useState<'success' | 'info' | 'warning' | 'error'>();
  const addLoading = () => {
    setLoading(isLoading + 1);
  };
  const removeLoading = () => {
    setLoading(isLoading > 0 ? isLoading - 1 : 0);
  };

  const createMessage = useCallback(
    (message: string, type: 'success' | 'info' | 'warning' | 'error') => {
      setMessage(message);
      setType(type);
    },
    [],
  );

  return (
    <InfoContext.Provider value={{ addLoading, removeLoading, createMessage }}>
      <Spin
        spinning={isLoading > 0}
        fullscreen
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      />
      {message && (
        <MessageStyle>
          <Alert
            message={message}
            type={type}
            showIcon
            closable={!!message}
            onClose={() => setMessage('')}
          />
        </MessageStyle>
      )}
      {props.children}
    </InfoContext.Provider>
  );
};
const MessageStyle = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
`;
