import { FC, memo } from 'react';
import { ScheduleEntity } from 'src/domain/schedule.entity';
import { Flex, Timeline, Typography } from 'antd';
import { RangeText } from 'src/components/Organisms/Schedule/RangeText';
import styled from 'styled-components';

type Props = {
  schedule: ScheduleEntity;
};
export const ScheduleConfirm: FC<Props> = memo((props) => {
  return (
    <Flex vertical={true}>
      <TitleStyle level={5} style={{ margin: '24px' }}>
        病院マスター管理（事務局 or 病院）
      </TitleStyle>
      <Timeline
        items={[
          {
            children: (
              <Flex vertical={true} gap={8}>
                病院マスター登録
                <TitleStyle level={5}>病院エントリー（大学）</TitleStyle>
              </Flex>
            ),
          },
          {
            children: (
              <Flex vertical={false} gap={32} justify={'space-between'}>
                エントリー
                <RangeText
                  startLabel={'エントリー開始'}
                  endLabel={'エントリー終了'}
                  rangeDate={props.schedule.hospitalEntry}
                />
              </Flex>
            ),
          },
          {
            children: (
              <Flex vertical={false} gap={32} justify={'space-between'}>
                エントリー数調整
                <RangeText
                  startLabel={'エントリー数調整開始'}
                  endLabel={'エントリー数調整終了'}
                  rangeDate={props.schedule.hospitalAdjustment}
                />
              </Flex>
            ),
          },
          {
            children: (
              <Flex vertical={true} gap={8}>
                <Flex vertical={false} gap={32} justify={'space-between'}>
                  再エントリー
                  <RangeText
                    startLabel={'再エントリー開始'}
                    endLabel={'再エントリー終了'}
                    rangeDate={props.schedule.hospitalReentry}
                  />
                </Flex>
                <TitleStyle level={5}>
                  薬局マスターメンテナンス（事務局）
                </TitleStyle>
              </Flex>
            ),
          },
          {
            children: (
              <Flex vertical={true} gap={8}>
                <Flex vertical={false} gap={32} justify={'space-between'}>
                  薬局マスターアップロード
                  <RangeText
                    startLabel={'開始'}
                    endLabel={'終了'}
                    rangeDate={props.schedule.pharmacyMaster}
                  />
                </Flex>
                <TitleStyle level={5}>薬局エントリー（大学）</TitleStyle>
              </Flex>
            ),
          },
          {
            children: (
              <Flex vertical={true} gap={8}>
                <Flex vertical={false} gap={32} justify={'space-between'}>
                  エントリー
                  <RangeText
                    startLabel={'エントリー開始'}
                    endLabel={'エントリー終了'}
                    rangeDate={props.schedule.pharmacyEntry}
                  />
                </Flex>
                <TitleStyle level={5}>薬局エントリー調整（事務局）</TitleStyle>
              </Flex>
            ),
          },
          {
            children: (
              <Flex vertical={false} gap={32} justify={'space-between'}>
                薬剤師会調整後再エントリー
                <RangeText
                  startLabel={'調整開始'}
                  endLabel={'調整終了'}
                  rangeDate={props.schedule.pharmacyAdjustment}
                />
              </Flex>
            ),
          },
          {
            children: '完了',
          },
        ]}
      />
    </Flex>
  );
});

const TitleStyle = styled(Typography.Title)`
  margin: 16px 0 0 0;
`;
