import { FC, memo } from 'react';
import { Flex, Typography } from 'antd';
import { NewsRow } from 'src/components/Molecules';
import { NewsEntity } from 'src/domain/news.entity';

type Props = {
  news: NewsEntity[];
};
export const NewsList: FC<Props> = memo((props) => {
  return (
    <Flex
      vertical={true}
      align={'flex-start'}
      style={{ alignSelf: 'stretch' }}
      gap={'middle'}
    >
      <Typography.Title level={5} style={{ margin: 0 }}>
        お知らせ
      </Typography.Title>
      <Flex
        vertical={true}
        align={'flex-start'}
        style={{ alignSelf: 'stretch' }}
        gap={'small'}
      >
        {props.news.map((news, i) => (
          <NewsRow news={news} key={i} />
        ))}
      </Flex>
    </Flex>
  );
});
