import { ThemeConfig } from 'antd';

export const themeConfig: ThemeConfig = {
  components: {
    Menu: {
      horizontalItemSelectedBg: '#000000',
      horizontalItemSelectedColor: '#FFFFFF',
      itemHoverBg: '#000000',
      itemHoverColor: '#FFFFFF',
      itemSelectedColor: '#FFFFFF',
    },
    Pagination: {
      itemActiveBg: '#FFFFFF',
      itemBg: '#FFFFFF',
      itemInputBg: '#FFFFFF',
      itemLinkBg: '#FFFFFF',
    },
    Table: {
      headerColor: '#FFFFFF',
      headerBg: '#000000',
    },
    Button: {
      colorPrimary: '#000000',
      primaryColor: '#FFFFFF',
    },
    Layout: {
      bodyBg: '#FFFFFF',
    },
  },
  token: {
    colorPrimary: '#000000',
    colorPrimaryHover: '#00000070',
  },
};
