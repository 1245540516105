import { FC, memo } from 'react';
import { useNewsListHooks } from 'src/hooks/pages/use.news.list.hooks';
import { Default } from 'src/components/Layouts/default';
import { NewsSearchBox } from 'src/components/Organisms/News/NewsSearchBox';
import { NewsTable } from 'src/components/Organisms/News/NewsTable';
import { Button } from 'antd';

export const NewsList: FC = memo(() => {
  const { onSearch, news, onStop, onMove, onAdd, page, total, onPage } =
    useNewsListHooks();

  return (
    <Default title={'お知らせ管理'} isSelect={5} contents={''}>
      <NewsSearchBox onSearch={onSearch} />
      <Button type={'primary'} size={'large'} onClick={onAdd}>
        お知らせ追加
      </Button>
      <NewsTable
        news={news}
        onChange={onMove}
        onStop={onStop}
        page={page}
        total={total}
        onPage={onPage}
      />
    </Default>
  );
});
