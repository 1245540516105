import { axiosInstance } from 'src/providers/axios.provider';
import {
  AreaRes,
  GetPrefectureRes,
  GroupRes,
  PrefectureRes,
  UniversityRes,
} from 'src/service/res/master';
import { AxiosResponse } from 'axios';
import { GroupReq } from 'src/service/req/master';
const path = '/master';
export const masterRepository = {
  area: async () => {
    return await axiosInstance.get<void, AxiosResponse<AreaRes>>(
      `${path}/area`,
    );
  },
  group: async (req: GroupReq) => {
    return await axiosInstance.get<GroupReq, AxiosResponse<GroupRes>>(
      `${path}/group`,
      {
        params: req,
      },
    );
  },
  university: async () => {
    return await axiosInstance.get<void, AxiosResponse<UniversityRes>>(
      `${path}/university`,
    );
  },
  prefecture: async () => {
    return await axiosInstance.get<void, AxiosResponse<PrefectureRes>>(
      `${path}/prefecture`,
    );
  },
  getPrefecture: async (id: string) => {
    return await axiosInstance.get<void, AxiosResponse<GetPrefectureRes>>(
      `${path}/prefecture/${id}`,
    );
  },
};
