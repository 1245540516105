import { useCallback, useContext, useEffect, useState } from 'react';
import { ScheduleEntity } from 'src/domain/schedule.entity';
import { scheduleRepository } from 'src/service/schedule.repository';
import dayjs from 'dayjs';
import { masterRepository } from 'src/service/master.repository';
import { AreaEntity } from 'src/domain/area.entity';
import { SearchEntity } from 'src/domain/search.entity';
import { PharmacyCollectEntity } from 'src/domain/pharmacy.collect.entity';
import { pharmacyRepository } from 'src/service/pharmacy.repository';
import { UniversityEntity } from 'src/domain/university.entity';
import { saveAs } from 'file-saver';
import { InfoContext } from 'src/providers/info.provider';

export const usePharmacyCollectHooks = () => {
  const [schedules, setSchedules] = useState<ScheduleEntity[]>([]);
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [pharmacies, setPharmacies] = useState<PharmacyCollectEntity[]>([]);
  const [footer, setFooter] = useState<PharmacyCollectEntity>();
  const [universities, setUniversities] = useState<UniversityEntity[]>([]);
  const { addLoading, removeLoading } = useContext(InfoContext);
  const search = useCallback(async (query: SearchEntity) => {
    addLoading();
    await pharmacyRepository
      .collect({ areaId: query.areaId.join(','), scheduleId: query.scheduleId })
      .then((data) => {
        setPharmacies(data.data.results);
        setFooter(data.data.summary);
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);

  const download = useCallback(async (query: SearchEntity) => {
    addLoading();
    await pharmacyRepository
      .exportCollect({
        areaId: query.areaId.join(','),
        scheduleId: query.scheduleId,
      })
      .then((data) => {
        const blob = new Blob([data.data], {
          type: data.data.type,
        });
        saveAs(blob, `薬局実習集計.xlsx`);
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);

  useEffect(() => {
    console.log('1');
    Promise.all([
      scheduleRepository.list().then((data) =>
        setSchedules(
          data.data.results.map((s) => {
            return {
              id: s.id,
              name: s.name,
              statusId: s.statusId,
              hospitalEntry: s.hospitalEntry.map((d) => dayjs(d)),
              hospitalAdjustment: s.hospitalAdjustment.map((d) => dayjs(d)),
              hospitalReentry: s.hospitalReentry.map((d) => dayjs(d)),
              pharmacyMaster: s.pharmacyMaster.map((d) => dayjs(d)),
              pharmacyEntry: s.pharmacyEntry.map((d) => dayjs(d)),
              pharmacyAdjustment: s.pharmacyAdjustment.map((d) => dayjs(d)),
            };
          }),
        ),
      ),
      masterRepository.area().then((data) => setAreas(data.data.results)),
      masterRepository
        .university()
        .then((data) => setUniversities(data.data.results)),
    ])
      .catch(() => null)
      .finally(removeLoading);
  }, []);
  return {
    schedules,
    areas,
    search,
    download,
    pharmacies,
    footer,
    universities,
  };
};
