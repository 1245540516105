import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountEntity, AccountSearchEntity } from 'src/domain/account.entity';
import { accountRepository } from 'src/service/account.repository';
import { InfoContext } from 'src/providers/info.provider';

export const useAccountsHooks = () => {
  const [accounts, setAccounts] = useState<AccountEntity[]>([]);
  const [search, setSearch] = useState<AccountSearchEntity>({});
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  const navigate = useNavigate();
  const onChange = useCallback((id: number) => {
    navigate(`/account/${id}`);
  }, []);

  const load = useCallback(async (form: AccountSearchEntity, page: number) => {
    setSearch(form);
    setPage(page);
    addLoading();
    await accountRepository
      .list({
        page: page,
        name: form.name,
        accountId: form.accountId,
        authId: form.authId,
      })
      .then((data) => {
        setAccounts(data.data.results);
        setTotal(data.data.count);
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);
  const onSearch = useCallback(
    async (form: AccountSearchEntity) => {
      await load(form, 1);
    },
    [load],
  );

  const onStop = useCallback(
    async (userId: number, authId: number, invalid: number) => {
      addLoading();
      await accountRepository
        .delete({
          userId,
          authId,
          invalid,
        })
        .then(() => {
          invalid === 1
            ? createMessage('アカウントを無効にしました。', 'info')
            : createMessage('アカウントを再開にしました。', 'info');
          onSearch(search);
        })
        .catch(() => null)
        .finally(removeLoading);
    },
    [search, onSearch],
  );

  const addHospital = useCallback(() => {
    navigate('/hospital/master/create');
  }, []);

  const addUniversity = useCallback(() => {
    navigate('/university/master/create');
  }, []);

  useEffect(() => {
    onSearch({}).then();
  }, [onSearch]);

  const onPage = useCallback(
    async (page: number) => {
      await load(search, page);
    },
    [load, search],
  );

  return {
    accounts,
    onStop,
    onChange,
    onSearch,
    addUniversity,
    addHospital,
    page,
    total,
    onPage,
  };
};
