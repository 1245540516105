import { FC, memo, useCallback, useState } from 'react';
import { SecondaryButton } from 'src/components/Atoms/SecondaryButton';
import { Button, Flex } from 'antd';
import { FileDragDrop } from 'src/components/Atoms/FileDragDrop';

type Props = {
  downloadBtnLbl: string;
  uploadBtnLbl: string;
  fileDropLabel: string;
  download: () => void;
  upload: (file: File) => void;
  disabled: boolean;
};
export const DownloadUpload: FC<Props> = memo((props) => {
  const [file, setFile] = useState<File>();
  const changeFile = useCallback((files: File[]) => {
    if (files.length > 0) {
      setFile(files[0]);
    } else {
      setFile(undefined);
    }
  }, []);
  const uploadClick = useCallback(() => {
    if (file) props.upload(file);
  }, [file]);
  return (
    <Flex vertical={true} align={'center'} gap={16}>
      <SecondaryButton
        size={'large'}
        style={{ width: '100%' }}
        disabled={props.disabled}
        onClick={props.download}
      >
        {props.downloadBtnLbl}
      </SecondaryButton>
      <Flex vertical={true} align={'center'}>
        <FileDragDrop
          label={
            <Flex vertical={true}>
              <div>ここに{props.fileDropLabel}を</div>
              <div>ドラッグするか</div>
              <div>アップロードしてください</div>
            </Flex>
          }
          multiple={false}
          accept={{ 'text/csv': ['.csv'] }}
          handleChange={changeFile}
          disabled={props.disabled}
        />
        <Button
          size={'large'}
          type={'primary'}
          disabled={file === undefined || props.disabled}
          style={{ width: '100%' }}
          onClick={uploadClick}
        >
          {props.uploadBtnLbl}
        </Button>
      </Flex>
    </Flex>
  );
});
