import { FC, memo } from 'react';
import { Button, Flex, Form } from 'antd';
import { AccountCreateEntity } from 'src/domain/account.entity';
import { AuthName } from 'src/common/types';

type Props = {
  account: AccountCreateEntity;
  onUpdate: () => void;
  onBack: () => void;
};
export const AccountConfirm: FC<Props> = memo((props) => {
  return (
    <Form
      layout="vertical"
      style={{ width: '600px' }}
      onFinish={props.onUpdate}
    >
      <Flex
        vertical={true}
        style={{ border: '1px solid #00000070', padding: '16px' }}
      >
        <Flex vertical={true}>
          <Form.Item label={'権限'} required={true} name={'authId'}>
            {AuthName[props.account.authId]}
          </Form.Item>
          <Form.Item label={'ログインID'} required={true} name={'accountId'}>
            {props.account.accountId}
          </Form.Item>
          <Form.Item label={'大学名'} required={true} name={'universityName'}>
            {props.account.universityName}
          </Form.Item>
          <Form.Item
            label={'省略大学名'}
            required={true}
            name={'universityOmissionName'}
          >
            {props.account.universityOmissionName}
          </Form.Item>
          <Form.Item label={'パスワード'} required={true} name={'password'}>
            ******
          </Form.Item>
        </Flex>
      </Flex>
      <Flex
        justify={'center'}
        style={{ width: '100%', margin: '16px' }}
        gap={32}
      >
        <Button onClick={props.onBack}>戻る</Button>
        <Button htmlType={'submit'} type="primary">
          登録
        </Button>
      </Flex>
    </Form>
  );
});
