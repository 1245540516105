import { FC, memo } from 'react';
import { Flex, Typography } from 'antd';
import { DownloadUpload } from 'src/components/Molecules/DownloadUpload';

type Props = {
  name: string;
  masterDownload: () => void;
  resultDownload: () => void;
  masterUpload: (file: File) => void;
  resultUpload: (file: File) => void;
  disabled: boolean;
};

export const PharmacyUpload: FC<Props> = memo((props) => {
  return (
    <Flex vertical={true} style={{ alignSelf: 'stretch' }}>
      <Typography.Title
        level={3}
        style={{
          color: '#FFFFFF',
          margin: 0,
          padding: '16px',
          textAlign: 'center',
          background: '#000000',
        }}
      >
        {props.name}
      </Typography.Title>
      <Flex
        vertical={false}
        justify={'space-evenly'}
        style={{ padding: '16px', border: '1px solid #000000' }}
      >
        <DownloadUpload
          disabled={false}
          downloadBtnLbl={'マスターダウンロード'}
          fileDropLabel={'薬局マスターCSV'}
          uploadBtnLbl={'マスター登録更新'}
          download={props.masterDownload}
          upload={props.masterUpload}
        />
        <DownloadUpload
          disabled={props.disabled}
          downloadBtnLbl={'調査結果フォーマットダウンロード'}
          fileDropLabel={'調査結果CSV'}
          uploadBtnLbl={'調査結果更新'}
          download={props.resultDownload}
          upload={props.resultUpload}
        />
      </Flex>
    </Flex>
  );
});
