import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { AccountSearch } from 'src/components/Organisms/Account/AccountSearch';
import { Button, Flex } from 'antd';
import { AccountTable } from 'src/components/Organisms/Account/AccountTable';
import { useAccountsHooks } from 'src/hooks/pages/use.accounts.hooks';

export const Accounts: FC = memo(() => {
  const {
    accounts,
    addHospital,
    addUniversity,
    onStop,
    onSearch,
    onChange,
    page,
    onPage,
    total,
  } = useAccountsHooks();
  return (
    <Default title={'ログインアカウント'} isSelect={5} contents={''}>
      <AccountSearch onSearch={onSearch} />
      <Flex vertical={false} gap={32}>
        <Button type={'primary'} size={'large'} onClick={addHospital}>
          病院アカウント追加
        </Button>
        <Button type={'primary'} size={'large'} onClick={addUniversity}>
          大学アカウント追加
        </Button>
      </Flex>
      <AccountTable
        accounts={accounts}
        onChange={onChange}
        onStop={onStop}
        page={page}
        total={total}
        onPage={onPage}
      />
    </Default>
  );
});
