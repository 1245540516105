import { useCallback, useContext, useState } from 'react';
import { NewsEntity } from 'src/domain/news.entity';
import dayjs from 'dayjs';
import { newsRepository } from 'src/service/news.repository';
import { dateApiFormat } from 'src/common/format';
import { useNavigate } from 'react-router-dom';
import { InfoContext } from 'src/providers/info.provider';

export const useNewsCreateHooks = () => {
  const [news, setNews] = useState<NewsEntity>({
    id: 0,
    date: dayjs(),
    content: '',
    invalid: 0,
  });

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  const onCreate = useCallback(async () => {
    addLoading();
    await newsRepository
      .create({
        content: news.content,
        date: news.date.format(dateApiFormat),
      })
      .then(() => {
        createMessage('お知らせを登録しました。', 'info');
        navigate('/news');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [news]);

  const onBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const onNext = useCallback((values: NewsEntity) => {
    setNews(values);
    setStep(1);
  }, []);

  return { news, onNext, onBack, onCreate, step };
};
