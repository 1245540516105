export const masterColumns = [
  '府県※',
  '地区※',
  'G番号※',
  '病院コード※',
  '薬局コード※',
  '薬局名称※',
  '薬局ふりがな※',
  '郵便番号※',
  '住所※',
  '電話番号※',
  '管理者※',
  '第1期定員※',
  '第2期定員※',
  '第3期定員※',
  '全期定員※',
  '備考',
];
export const columnsEntry = ['薬局コード※'];
