import { FC, memo } from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { EntryEntity } from 'src/domain/entry.entity';
import { PharmacyEntity } from 'src/domain/pharmacy.entity';
import { PharmacyEntryCell } from 'src/components/Organisms/Pharmacy/PharmacyEntryCell';

type Props = {
  isOpen: boolean;
  pharmacy: PharmacyEntity;
  entry?: EntryEntity;
  sumEntry?: EntryEntity;
  onChange: (pharmacyId: number, period: string, num: number) => void;
  disabled: boolean;
};
export const PharmacyEntryRow: FC<Props> = memo((props) => {
  return (
    <Flex
      vertical={false}
      align={'flex-start'}
      style={{
        border: '1px solid #000000',
        visibility: props.isOpen ? 'inherit' : 'hidden',
        display: props.isOpen ? 'inherit' : 'none',
        alignSelf: 'stretch',
      }}
    >
      <CellStyle
        vertical={true}
        align={'start'}
        justify={'center'}
        style={{
          alignSelf: 'stretch',
          minWidth: '280px',
          flex: '1 0 0',
          height: 'inherit',
          padding: '8px',
        }}
      >
        <div>{props.pharmacy.pharmacyNo}</div>
        <div>{props.pharmacy.name}</div>
      </CellStyle>
      <CellStyle
        vertical={false}
        style={{
          width: '160px',
          alignSelf: 'stretch',
          height: 'inherit',
          padding: '8px',
        }}
      >
        {props.pharmacy.capacity?.note}
        <div></div>
      </CellStyle>
      <Flex vertical={true} style={{ width: '160px' }}>
        <CellHeaderStyle
          vertical={false}
          style={{ padding: '8px' }}
          align={'center'}
        >
          定員
        </CellHeaderStyle>
        <CellStyle style={{ padding: '8px' }} align={'center'} vertical={false}>
          エントリー
        </CellStyle>
        <CellHeaderStyle
          vertical={false}
          style={{ padding: '8px' }}
          align={'center'}
        >
          全校エントリー
        </CellHeaderStyle>
      </Flex>
      <PharmacyEntryCell
        capacity={props.pharmacy.capacity?.allCount ?? 0}
        entry={
          (props.entry?.firstCount ?? 0) +
          (props.entry?.secondCount ?? 0) +
          (props.entry?.thirdCount ?? 0)
        }
        sumEntry={
          (props.sumEntry?.firstCount ?? 0) +
          (props.sumEntry?.secondCount ?? 0) +
          (props.sumEntry?.thirdCount ?? 0)
        }
        period={'all'}
        disabled={props.disabled}
        pharmacyId={props.pharmacy.id}
      />
      <PharmacyEntryCell
        capacity={props.pharmacy.capacity?.firstCount ?? 0}
        entry={props.entry?.firstCount}
        sumEntry={props.sumEntry?.firstCount ?? 0}
        period={'firstCount'}
        disabled={props.disabled}
        pharmacyId={props.pharmacy.id}
        onChange={props.onChange}
      />
      <PharmacyEntryCell
        capacity={props.pharmacy.capacity?.secondCount ?? 0}
        entry={props.entry?.secondCount}
        sumEntry={props.sumEntry?.secondCount ?? 0}
        period={'secondCount'}
        disabled={props.disabled}
        pharmacyId={props.pharmacy.id}
        onChange={props.onChange}
      />
      <PharmacyEntryCell
        capacity={props.pharmacy.capacity?.thirdCount ?? 0}
        entry={props.entry?.thirdCount}
        sumEntry={props.sumEntry?.thirdCount ?? 0}
        period={'thirdCount'}
        disabled={props.disabled}
        pharmacyId={props.pharmacy.id}
        onChange={props.onChange}
      />
    </Flex>
  );
});

const CellStyle = styled(Flex)`
  border: 1px solid #000000;
  height: 39px;
`;

const CellHeaderStyle = styled(CellStyle)`
  background: #000000;
  color: #ffffff;
  border-color: #ffffff;
`;
