import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { EntryEntity } from 'src/domain/entry.entity';
import { ScheduleContext } from 'src/providers/schedule.provider';
import dayjs from 'dayjs';
import { UniversityEntity } from 'src/domain/university.entity';
import { HospitalPharmacy } from 'src/domain/hospital.entity';
import { hospitalRepository } from 'src/service/hospital.repository';
import { masterRepository } from 'src/service/master.repository';
import { AuthType } from 'src/common/types';
import { pharmacyRepository } from 'src/service/pharmacy.repository';
import { AreaEntity } from 'src/domain/area.entity';
import { PharmacyAddEntity } from 'src/domain/pharmacy.add.entity';
import { InfoContext } from 'src/providers/info.provider';

export const usePharmacyEntryHooks = () => {
  const [entry, setEntry] = useState<{
    [key: string]: EntryEntity;
  }>({});

  const [universities, setUniversities] = useState<UniversityEntity[]>([]);
  const { schedule, user } = useContext(ScheduleContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [hospital, setHospital] = useState<HospitalPharmacy[]>([]);
  const [universityId, setUniversityId] = useState<number>();
  const [sumEntry, setSumEntry] = useState<{
    [key: number]: EntryEntity;
  }>({});
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  const group = useCallback(
    async (universityId: number) => {
      setUniversityId(universityId);
      if (schedule) {
        addLoading();
        await Promise.all([
          await hospitalRepository
            .pharmacy({
              scheduleId: schedule.id,
              universityId,
            })
            .then((data) => setHospital(data.data.results)),
          await pharmacyRepository
            .getEntry({
              scheduleId: schedule.id,
              universityId,
            })
            .then((data) => {
              setEntry(data.data.results);
              setSumEntry(data.data.sum);
            }),
        ])
          .catch(() => null)
          .finally(removeLoading);
      }
    },
    [schedule],
  );

  useEffect(() => {
    if (!schedule) return;
    addLoading();
    Promise.all([
      masterRepository
        .university()
        .then((data) => setUniversities(data.data.results)),
      masterRepository.area().then((data) => setAreas(data.data.results)),
    ])
      .catch(() => null)
      .finally(removeLoading);
    if (user?.authId === AuthType.University && user.universityId) {
      group(user.universityId).then();
    }
  }, [schedule, user, group]);

  const onChange = useCallback(
    (pharmacyId: number, period: string, num: number) => {
      if (!schedule) return;
      if (!entry.hasOwnProperty(pharmacyId)) {
        entry[pharmacyId] = {
          scheduleId: schedule.id,
          secondCount: 0,
          thirdCount: 0,
          firstCount: 0,
        };
      }
      setEntry({
        ...entry,
        [pharmacyId]: { ...entry[pharmacyId], [period]: num },
      });
    },
    [entry],
  );
  const onUniversitySelect = useCallback(
    async (universityId: number) => {
      await group(universityId);
    },
    [group],
  );

  const disabled = useMemo(() => {
    if (user) {
      if (user.authId === AuthType.Hospital) return true;
    }
    if (!schedule || schedule.pharmacyEntry.length < 2) return true;
    const now = dayjs().set('second', 0).set('milliseconds', 0);
    return !(
      0 >= schedule.pharmacyEntry[0].diff(now) &&
      0 <= schedule.pharmacyEntry[1].diff(now)
    );
  }, [schedule, user]);

  const onAddGroup = useCallback(
    async (params: PharmacyAddEntity) => {
      if (!schedule || !universityId) return;
      addLoading();
      await hospitalRepository
        .addPharmacyGroup({
          scheduleId: schedule?.id,
          universityId,
          areaId: params.areaId,
          name: params.hospitalName,
        })
        .then((data) => {
          setHospital([...hospital, ...data.data.results]);
          setIsModalOpen(false);
        })
        .catch(() => null)
        .finally(removeLoading);
    },
    [schedule?.id, universityId, hospital],
  );

  const update = useCallback(async () => {
    if (!schedule || !universityId) return;
    addLoading();
    await pharmacyRepository
      .postEntry({
        scheduleId: schedule.id,
        universityId,
        entries: entry,
      })
      .then(() => createMessage('エントリーを登録しました', 'info'))
      .catch(() => null)
      .finally(removeLoading);
  }, [entry, schedule, universityId]);
  return {
    entry,
    onChange,
    universities,
    schedule,
    disabled,
    onUniversitySelect,
    isModalOpen,
    setIsModalOpen,
    onAddGroup,
    areas,
    user,
    hospital,
    universityId,
    update,
    sumEntry,
  };
};
