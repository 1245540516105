import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { pharmacyRepository } from 'src/service/pharmacy.repository';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import { masterRepository } from 'src/service/master.repository';
import { checkCsv } from 'src/common/check.csv';
import { columnsEntry, masterColumns } from 'src/common/columns';
import { UniversityEntity } from 'src/domain/university.entity';
import { PharmacyMasterEntity } from 'src/domain/pharmacy.master.entity';
import { PharmacyEntryEntity } from 'src/domain/pharmacy.entry.entity';
import { AxiosError, HttpStatusCode } from 'axios';
import iconv from 'iconv-lite';
import { InfoContext } from 'src/providers/info.provider';
import dayjs from 'dayjs';

export const usePharmacyMasterUploadHooks = () => {
  const [errors, setErrors] = useState<string[]>([]);
  const { schedule } = useContext(ScheduleContext);
  const params = useParams<{ id: string }>();
  const [name, setName] = useState<string>();
  const [universities, setUniversities] = useState<UniversityEntity[]>([]);
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  const readFileAsText = async (file: Blob): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      const readCsv = () => {
        const csvString = iconv.decode(
          Buffer.from(reader.result as string),
          'Shift_JIS',
        );
        resolve(csvString);
      };
      reader.onerror = () => reject(reader.error);
      reader.onload = readCsv;
      reader.readAsArrayBuffer(file);
    });
  };

  const mapCSVToArray = (csv: string): string[][] => {
    return csv
      .split('\n')
      .map((row) =>
        row
          .split(',')
          .map((col) => col.replaceAll('"', '').replaceAll('\r', '')),
      );
  };

  useEffect(() => {
    if (!params.id) return;
    addLoading();
    Promise.all([
      masterRepository
        .getPrefecture(params.id)
        .then((data) => setName(`${data.data.name} 薬剤師会`)),
      masterRepository
        .university()
        .then((data) => setUniversities(data.data.results)),
    ])
      .catch(() => null)
      .finally(removeLoading);
  }, [params.id]);

  const downloadMaster = useCallback(async () => {
    if (!schedule || !params.id) return;
    addLoading();
    await pharmacyRepository
      .exportMaster({ scheduleId: schedule.id, prefectureId: params.id })
      .then((data) => {
        const blob = new Blob([iconv.encode(data.data, 'Shift_JIS')], {
          type: data.data.type,
        });
        iconv.encode(data.data, 'Shift_JIS');
        saveAs(blob, `${name}_薬剤師マスター.csv`);
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [schedule, params.id, name]);

  const downloadResult = useCallback(async () => {
    if (!schedule || !params.id) return;
    addLoading();
    await pharmacyRepository
      .exportEntry({ scheduleId: schedule.id, prefectureId: params.id })
      .then((data) => {
        const blob = new Blob([iconv.encode(data.data, 'Shift_JIS')], {
          type: data.data.type,
        });
        iconv.encode(data.data, 'Shift_JIS');
        saveAs(blob, `${name}_薬剤師調整結果.csv`);
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [schedule, params.id, name]);

  const uploadMaster = useCallback(
    async (file: File) => {
      if (!schedule || !params.id) return;
      addLoading();
      const csv = await readFileAsText(file);
      const data = mapCSVToArray(csv);
      const { err, colMap } = checkCsv(masterColumns, data);
      if (err.length > 0) {
        removeLoading();
        setErrors(err);
        createMessage('アップロードに失敗しました。', 'error');
        return;
      }
      const pharmacyMasterEntities: PharmacyMasterEntity[] = [];
      for (let i = 1; i < data.length; i++) {
        if (data[i].length === 1) break;
        pharmacyMasterEntities.push({
          lineNumber: i,
          prefectureName: data[i][colMap['府県※']].replaceAll('"', ''),
          areaNo: data[i][colMap['地区※']].replaceAll('"', ''),
          groupName: data[i][colMap['G番号※']].replaceAll('"', ''),
          hospitalNo: data[i][colMap['病院コード※']].replaceAll('"', ''),
          pharmacyNo: data[i][colMap['薬局コード※']].replaceAll('"', ''),
          name: data[i][colMap['薬局名称※']].replaceAll('"', ''),
          kana: data[i][colMap['薬局ふりがな※']].replaceAll('"', ''),
          zipCode: data[i][colMap['郵便番号※']].replaceAll('"', ''),
          address: data[i][colMap['住所※']].replaceAll('"', ''),
          tel: data[i][colMap['電話番号※']].replaceAll('"', ''),
          managerName: data[i][colMap['管理者※']].replaceAll('"', ''),
          firstCount: data[i][colMap['第1期定員※']].replaceAll('"', ''),
          secondCount: data[i][colMap['第2期定員※']].replaceAll('"', ''),
          thirdCount: data[i][colMap['第3期定員※']].replaceAll('"', ''),
          allCount: data[i][colMap['全期定員※']].replaceAll('"', ''),
          note: data[i][colMap['備考']].replaceAll('"', ''),
        });
      }
      pharmacyRepository
        .uploadMaster({
          results: pharmacyMasterEntities,
          prefId: Number(params.id),
          scheduleId: schedule.id,
        })
        .then((data) => {
          setErrors(data.data.results);
          if (data.data.results.length > 0)
            createMessage('アップロードに失敗しました。', 'error');
          else createMessage('アップロードに成功しました。', 'info');
        })
        .catch((data) => {
          if (data instanceof AxiosError) {
            if (data.response?.status === HttpStatusCode.BadRequest) {
              createMessage('アップロードに失敗しました。', 'error');
              setErrors(
                (data.response.data.message as string[]).map((message) =>
                  message.replace(/results\.\d+\./, ''),
                ),
              );
            }
          }
        })
        .finally(removeLoading);
    },
    [schedule?.id, params.id],
  );

  const uploadResult = useCallback(
    async (file: File) => {
      if (!schedule || !params.id) return;
      addLoading();
      const csv = await readFileAsText(file);
      const data = mapCSVToArray(csv);
      const columns = [...columnsEntry];
      universities.forEach((university) => {
        columns.push(`${university.universityOmissionName}1期`);
        columns.push(`${university.universityOmissionName}2期`);
        columns.push(`${university.universityOmissionName}3期`);
      });
      const { err, colMap } = checkCsv(columns, data);
      if (err.length > 0) {
        removeLoading();
        setErrors(err);
        createMessage('アップロードに失敗しました。', 'error');
        return;
      }
      const pharmacyEntryEntities: PharmacyEntryEntity[] = [];
      for (let i = 1; i < data.length; i++) {
        if (data[i].length === 1) break;
        const param: PharmacyEntryEntity = {
          lineNumber: i,
          pharmacyNo: data[i][colMap['薬局コード※']].replaceAll('"', ''),
          entries: [],
        };
        universities.forEach((university) => {
          param.entries.push({
            lineNumber: i,
            scheduleId: schedule.id,
            universityId: university.id,
            firstCount: data[i][
              colMap[`${university.universityOmissionName}1期`]
            ].replaceAll('"', ''),
            secondCount: data[i][
              colMap[`${university.universityOmissionName}2期`]
            ].replaceAll('"', ''),
            thirdCount: data[i][
              colMap[`${university.universityOmissionName}3期`]
            ].replaceAll('"', ''),
          });
        });
        pharmacyEntryEntities.push(param);
      }
      pharmacyRepository
        .uploadEntry({
          results: pharmacyEntryEntities,
          scheduleId: schedule.id,
          prefId: Number(params.id),
        })
        .then((data) => {
          setErrors(data.data.results);
          if (data.data.results.length > 0)
            createMessage('アップロードに失敗しました。', 'error');
          else createMessage('アップロードに成功しました。', 'info');
        })
        .catch((data) => {
          if (data instanceof AxiosError) {
            if (data.response?.status === HttpStatusCode.BadRequest) {
              createMessage('アップロードに失敗しました。', 'error');
              setErrors(
                (data.response.data.message as string[]).map((message) =>
                  message
                    .replace(/results\.\d+\./, '')
                    .replace(/entries\.\d+\./, ''),
                ),
              );
            }
          }
        })
        .finally(removeLoading);
    },
    [universities, schedule?.id],
  );

  const disabled = useMemo(() => {
    if (!schedule || schedule.pharmacyAdjustment.length < 2) return true;
    const now = dayjs().set('second', 0).set('milliseconds', 0);
    return !(
      0 >= schedule.pharmacyAdjustment[0].diff(now) &&
      0 <= schedule.pharmacyAdjustment[1].diff(now)
    );
  }, [schedule]);

  return {
    name,
    downloadMaster,
    downloadResult,
    uploadMaster,
    uploadResult,
    errors,
    disabled,
    schedule,
  };
};
