import React from 'react';
import { RenderRouter } from 'src/routers';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { themeConfig } from 'src/components/theme';
import { ScheduleProvider } from 'src/providers/schedule.provider';
import { AxiosInterceptor } from 'src/providers/axios.provider';
import { InfoProvider } from 'src/providers/info.provider';

function App() {
  return (
    <BrowserRouter>
      <ConfigProvider theme={themeConfig}>
        <InfoProvider>
          <ScheduleProvider>
            <AxiosInterceptor>
              <RenderRouter />
            </AxiosInterceptor>
          </ScheduleProvider>
        </InfoProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
}

export default App;
