import { useRoutes } from 'react-router-dom';
import { Home } from 'src/pages/home';
import { HospitalEntry } from 'src/pages/hospital.entry';
import { HospitalCollect } from 'src/pages/hospital.collect';
import { PharmacyEntry } from 'src/pages/pharmacy.entry';
import { PharmacyCollect } from 'src/pages/pharmacy.collect';
import { MasterInfoSearch } from 'src/pages/master.info.search';
import { HospitalMasterSelect } from 'src/pages/hospital.master.select';
import { HospitalMasterEdit } from 'src/pages/hospital.master.edit';
import { PharmacyMasterSelect } from 'src/pages/pharmacy.master.select';
import { PharmacyMasterUpload } from 'src/pages/pharmacy.master.upload';
import { Accounts } from 'src/pages/accounts';
import { HospitalMasterCreate } from 'src/pages/hospital.master.create';
import { AccountUniversityCreate } from 'src/pages/account.university.create';
import { NewsList } from 'src/pages/news.list';
import { NewsCreate } from 'src/pages/news.create';
import { NewsEdit } from 'src/pages/news.edit';
import { ScheduleDetail } from 'src/pages/schedule.detail';
import { ScheduleCreate } from 'src/pages/schedule.create';
import { Login } from 'src/pages/login';
import { AccountPassword } from 'src/pages/account.password';
import { ScheduleEdit } from 'src/pages/schedule.edit';

export const RenderRouter = () =>
  useRoutes([
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/hospital/entry',
      element: <HospitalEntry />,
    },
    {
      path: '/hospital/collect',
      element: <HospitalCollect />,
    },
    {
      path: '/pharmacy/entry',
      element: <PharmacyEntry />,
    },
    {
      path: '/pharmacy/collect',
      element: <PharmacyCollect />,
    },
    {
      path: '/master/info',
      element: <MasterInfoSearch />,
    },
    {
      path: '/hospital/master',
      element: <HospitalMasterSelect />,
    },
    {
      path: '/hospital/master/edit/:id',
      element: <HospitalMasterEdit />,
    },
    {
      path: '/hospital/master/create',
      element: <HospitalMasterCreate />,
    },
    {
      path: '/university/master/create',
      element: <AccountUniversityCreate />,
    },
    {
      path: '/pharmacy/master',
      element: <PharmacyMasterSelect />,
    },
    {
      path: '/pharmacy/master/upload/:id',
      element: <PharmacyMasterUpload />,
    },
    {
      path: '/accounts',
      element: <Accounts />,
    },
    {
      path: '/account/:id',
      element: <AccountPassword />,
    },
    {
      path: '/news',
      element: <NewsList />,
    },
    {
      path: '/news/create',
      element: <NewsCreate />,
    },
    {
      path: '/news/edit/:id',
      element: <NewsEdit />,
    },
    {
      path: '/schedule',
      element: <ScheduleDetail />,
    },
    {
      path: '/schedule/create',
      element: <ScheduleCreate />,
    },
    {
      path: '/schedule/edit',
      element: <ScheduleEdit />,
    },
  ]);
