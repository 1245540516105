import { useCallback, useContext, useEffect, useState } from 'react';
import { NewsEntity } from 'src/domain/news.entity';
import dayjs from 'dayjs';
import { useNavigate, useParams } from 'react-router-dom';
import { newsRepository } from 'src/service/news.repository';
import { dateApiFormat } from 'src/common/format';
import { InfoContext } from 'src/providers/info.provider';

export const useNewsEditHooks = () => {
  const [news, setNews] = useState<NewsEntity>();

  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  useEffect(() => {
    if (!params.id) return;
    addLoading();
    newsRepository
      .get(params.id)
      .then((data) =>
        setNews({
          id: data.data.id,
          content: data.data.content,
          date: dayjs(data.data.date),
          invalid: data.data.invalid,
        }),
      )
      .catch(() => null)
      .finally(removeLoading);
  }, [params.id]);

  const [step, setStep] = useState(0);

  const onCreate = useCallback(async () => {
    if (!news) return;
    addLoading();
    newsRepository
      .update({
        id: Number(params.id),
        content: news.content,
        date: news.date.format(dateApiFormat),
        invalid: 0,
      })
      .then(() => {
        createMessage('お知らせを更新しました。', 'info');
        navigate('/news');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [news, params.id]);

  const onBack = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const onNext = useCallback(
    (values: NewsEntity) => {
      setNews({ ...values, id: Number(params.id) });
      setStep(1);
    },
    [params.id],
  );

  return { news, onNext, onBack, onCreate, step };
};
