import { useContext, useEffect, useState } from 'react';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { NewsEntity } from 'src/domain/news.entity';
import { newsRepository } from 'src/service/news.repository';
import { dateApiFormat } from 'src/common/format';
import dayjs from 'dayjs';
import { InfoContext } from 'src/providers/info.provider';

export const useHomeHooks = () => {
  const { user } = useContext(ScheduleContext);
  const [news, setNews] = useState<NewsEntity[]>([]);
  const { addLoading, removeLoading } = useContext(InfoContext);
  useEffect(() => {
    addLoading();
    newsRepository
      .home({
        startDate: dayjs().format(dateApiFormat),
      })
      .then((data) => {
        setNews(
          data.data.results.map((news) => {
            return {
              id: news.id,
              content: news.content,
              date: dayjs(news.date),
              invalid: news.invalid,
            };
          }),
        );
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);

  return { news, user };
};
