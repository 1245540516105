import {
  PharmacyCollectReq,
  PharmacyExportCollectReq,
  PharmacyExportEntryReq,
  PharmacyExportExcelReq,
  PharmacyExportMasterReq,
  PharmacyGetEntryReq,
  PharmacyListReq,
  PharmacyPostEntryReq,
  PharmacyUploadEntryReq,
  PharmacyUploadMasterReq,
} from 'src/service/req/pharmacy';
import { axiosInstance } from 'src/providers/axios.provider';
import { AxiosResponse } from 'axios';
import {
  PharmacyCollectRes,
  PharmacyGetEntryRes,
  PharmacyListRes,
  UploadRes,
} from 'src/service/res/pharmacy';

const path = '/pharmacy';
export const pharmacyRepository = {
  list: async (req: PharmacyListReq) => {
    return await axiosInstance.get<
      PharmacyListReq,
      AxiosResponse<PharmacyListRes>
    >(`${path}`, {
      params: req,
    });
  },
  exportMaster: async (req: PharmacyExportMasterReq) => {
    return await axiosInstance.get<PharmacyExportMasterReq, AxiosResponse>(
      `${path}/csv/master`,
      { params: req },
    );
  },
  exportEntry: async (req: PharmacyExportEntryReq) => {
    return await axiosInstance.get<PharmacyExportEntryReq, AxiosResponse>(
      `${path}/csv/entry`,
      { params: req },
    );
  },
  postEntry: async (req: PharmacyPostEntryReq) => {
    return await axiosInstance.post<PharmacyPostEntryReq, AxiosResponse>(
      `${path}/entry`,
      req,
    );
  },
  getEntry: async (req: PharmacyGetEntryReq) => {
    return await axiosInstance.get<
      PharmacyGetEntryReq,
      AxiosResponse<PharmacyGetEntryRes>
    >(`${path}/entry`, { params: req });
  },
  collect: async (req: PharmacyCollectReq) => {
    return await axiosInstance.get<
      PharmacyCollectReq,
      AxiosResponse<PharmacyCollectRes>
    >(`${path}/collect`, { params: req });
  },
  exportCollect: async (req: PharmacyExportCollectReq) => {
    return await axiosInstance.get<PharmacyExportCollectReq, AxiosResponse>(
      `${path}/export/collect`,
      { params: req, responseType: 'arraybuffer' },
    );
  },
  uploadMaster: async (req: PharmacyUploadMasterReq) => {
    return await axiosInstance.post<
      PharmacyUploadMasterReq,
      AxiosResponse<UploadRes>
    >(`${path}/upload/master`, req);
  },
  uploadEntry: async (req: PharmacyUploadEntryReq) => {
    return await axiosInstance.post<
      PharmacyUploadEntryReq,
      AxiosResponse<UploadRes>
    >(`${path}/upload/entry`, req);
  },
  exportExcel: async (req: PharmacyExportExcelReq) => {
    return await axiosInstance.get<PharmacyExportExcelReq, AxiosResponse>(
      `${path}/export/excel`,
      { params: req, responseType: 'arraybuffer' },
    );
  },
};
