import React, { FC, memo } from 'react';
import { Button, Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/es/table/interface';
import { AccountEntity } from 'src/domain/account.entity';
import { AuthName, AuthType } from 'src/common/types';
type Props = {
  accounts: AccountEntity[];
  onChange: (id: number) => void;
  onStop: (id: number, authId: number, invalid: number) => void;
  page: number;
  total: number;
  onPage: (page: number) => void;
};
export const AccountTable: FC<Props> = memo((props) => {
  const columns: ColumnsType<AccountEntity> = [
    {
      title: '権限',
      align: 'center',
      render: (value: AccountEntity) => <div>{AuthName[value.authId]}</div>,
    },
    {
      title: '名前',
      dataIndex: 'name',
      align: 'start',
    },
    {
      title: 'ログインID',
      dataIndex: 'accountId',
      align: 'start',
    },
    {
      title: 'パスワード変更',
      align: 'center',
      render: (value: AccountEntity) => (
        <Button type={'primary'} onClick={() => props.onChange(value.userId)}>
          変更
        </Button>
      ),
    },
    {
      title: 'アカウント停止/再開',
      align: 'center',
      render: (value: AccountEntity) => (
        <Popconfirm
          title={value.invalid === 1 ? '再開' : '停止'}
          description={
            value.invalid === 1 ? '再開しますか？' : '停止しますか？'
          }
          onConfirm={() =>
            props.onStop(
              value.userId,
              value.authId,
              value.invalid === 1 ? 0 : 1,
            )
          }
          okText={value.invalid === 1 ? '再開' : '停止'}
          cancelText="キャンセル"
        >
          <Button type={'primary'} disabled={value.authId === AuthType.Admin}>
            {value.invalid === 1 ? '再開' : '停止'}
          </Button>
        </Popconfirm>
      ),
    },
  ];
  return (
    <Table
      rowKey={'userId'}
      columns={columns}
      pagination={{
        current: props.page,
        total: props.total,
        pageSize: 50,
        onChange: props.onPage,
        showSizeChanger: false,
      }}
      dataSource={props.accounts}
    />
  );
});
