import { FC, memo } from 'react';
import { DatePicker, Form } from 'antd';
import type { Rule } from 'rc-field-form/lib/interface';
import { dateTimeFormat } from 'src/common/format';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'dayjs/locale/ja';
type Props = {
  label: string;
  name: string;
  rules: Rule[];
  onChange?: () => void;
};
export const RangeForm: FC<Props> = memo((props) => {
  return (
    <Form.Item
      label={props.label}
      name={props.name}
      rules={props.rules}
      style={{ width: '400px' }}
    >
      <DatePicker.RangePicker
        locale={locale}
        showTime
        onChange={props.onChange}
        format={dateTimeFormat}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );
});
