import { FC, memo } from 'react';
import { Col, Flex, Typography } from 'antd';
import { CaretRightFilled } from '@ant-design/icons';
type Props = {
  title: string;
  items: {
    name: string;
    link: string;
  }[];
};
export const HomeMenu: FC<Props> = memo((props) => {
  return (
    <Col style={{ padding: '8px' }}>
      <Flex
        vertical={true}
        align={'flex-start'}
        style={{ border: '1px solid #000000', width: 'fit-content' }}
      >
        <Flex
          vertical={false}
          align={'center'}
          justify={'center'}
          style={{
            width: '100%',
            minWidth: '240px',
            padding: '8px 0',
            background: '#000000',
          }}
        >
          <Typography.Text style={{ color: '#FFFFFF' }}>
            {props.title}
          </Typography.Text>
        </Flex>
        <Flex vertical={true} style={{ width: '100%' }}>
          {props.items.map((item, i) => (
            <Typography.Link
              key={i}
              href={item.link}
              style={{ padding: '8px', width: '100%' }}
            >
              <Flex vertical={false}>
                <CaretRightFilled />
                {item.name}
              </Flex>
            </Typography.Link>
          ))}
        </Flex>
      </Flex>
    </Col>
  );
});
