import { FC, memo, useCallback, useEffect, useState } from 'react';
import { Flex, Form, Input, Modal, Select } from 'antd';
import { AreaEntity } from 'src/domain/area.entity';
import { useForm, useWatch } from 'antd/es/form/Form';
import { PharmacyAddEntity } from 'src/domain/pharmacy.add.entity';

type Props = {
  areas: AreaEntity[];
  isModalOpen: boolean;
  handleOk: (params: PharmacyAddEntity) => void;
  handleCancel: () => void;
};

export const PharmacyGroupAdd: FC<Props> = memo((props) => {
  const [form] = useForm<PharmacyAddEntity>();
  const [submittable, setSubmittable] = useState<boolean>(false);
  const values = useWatch([], form);
  const onClick = useCallback(() => {
    props.handleOk(values);
  }, [props.handleOk, values]);
  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values, props.isModalOpen]);
  return (
    <Modal
      title="薬局グループの追加"
      open={props.isModalOpen}
      onOk={onClick}
      okButtonProps={{ disabled: !submittable }}
      onCancel={props.handleCancel}
    >
      <Form layout="vertical" form={form}>
        <Flex vertical={false} gap={16}>
          <Form.Item
            label="住所"
            style={{ width: '160px' }}
            name="areaId"
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Select placeholder={'住所'}>
              {props.areas.map((area) => (
                <Select.Option key={area.id} value={area.id}>
                  {area.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="病院名"
            name="hospitalName"
            required={true}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input />
          </Form.Item>
        </Flex>
      </Form>
    </Modal>
  );
});
