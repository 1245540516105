import { axiosInstance } from 'src/providers/axios.provider';
import { AxiosResponse } from 'axios';
import { NewsGetRes, NewsHomeRes, NewsListRes } from 'src/service/res/news';
import {
  NewsCreateReq,
  NewsHomeReq,
  NewsListReq,
  NewsUpdateReq,
} from 'src/service/req/news';

const path = `/news`;
export const newsRepository = {
  list: async (query: NewsListReq) => {
    return await axiosInstance.get<NewsListReq, AxiosResponse<NewsListRes>>(
      `${path}`,
      {
        params: query,
      },
    );
  },
  home: async (query: NewsHomeReq) => {
    return await axiosInstance.get<NewsHomeReq, AxiosResponse<NewsHomeRes>>(
      `${path}/home`,
      {
        params: query,
      },
    );
  },
  get: async (id: string) => {
    return await axiosInstance.get<void, AxiosResponse<NewsGetRes>>(
      `${path}/${id}`,
    );
  },
  create: async (req: NewsCreateReq) => {
    return await axiosInstance.post<NewsCreateReq, AxiosResponse>(path, req);
  },
  update: async (req: NewsUpdateReq) => {
    return await axiosInstance.patch<NewsUpdateReq, AxiosResponse>(path, req);
  },
};
