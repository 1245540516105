export enum AuthType {
  Admin = 1,
  Hospital,
  University,
}

export const AuthName: { [key in AuthType]: string } = {
  [AuthType.Admin]: '管理者',
  [AuthType.Hospital]: '病院',
  [AuthType.University]: '大学',
};
