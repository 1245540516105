import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { HomeMenus, NewsList } from 'src/components/Organisms/Common';
import { useHomeHooks } from 'src/hooks/pages/use.home.hooks';

export const Home: FC = memo(() => {
  const { user, news } = useHomeHooks();
  return (
    <Default
      title={user?.name ?? ''}
      isSelect={0}
      contents={
        <>
          病院実習エントリー画面および薬局実習エントリー画面は受け付け期間中のみ機能します。
          <a
            href="https://entry.kinki-chosei.net/manual_for_hospitals.pdf"
            target="_blank"
            style={{ padding: '8px', textAlign: 'center' }}
          >
            実習エントリーシステム 操作マニュアル（病院向け）
          </a>
        </>
      }
    >
      {user && <HomeMenus auth={user.authId} />}
      <NewsList news={news} />
    </Default>
  );
});
