import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GroupHospitalEntity } from 'src/domain/group.hospital.entity';
import { EntryEntity } from 'src/domain/entry.entity';
import { ScheduleContext } from 'src/providers/schedule.provider';
import dayjs from 'dayjs';
import { UniversityEntity } from 'src/domain/university.entity';
import { hospitalRepository } from 'src/service/hospital.repository';
import { AuthType } from 'src/common/types';
import { masterRepository } from 'src/service/master.repository';
import { InfoContext } from 'src/providers/info.provider';

export const useHospitalEntryHooks = () => {
  const [groups, setGroups] = useState<GroupHospitalEntity[]>([]);
  const [entry, setEntry] = useState<{
    [key: number]: { [key: number]: EntryEntity };
  }>({});
  const [sumEntry, setSumEntry] = useState<{
    [key: number]: EntryEntity;
  }>({});
  const [universityId, setUniversityId] = useState<number>();

  const [universities, setUniversities] = useState<UniversityEntity[]>([]);
  const { schedule, user } = useContext(ScheduleContext);
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);

  const getEntry = useCallback(
    async (id: number) => {
      if (schedule) {
        setUniversityId(id);
        addLoading();
        await hospitalRepository
          .getEntry({
            universityId: id,
            scheduleId: schedule?.id,
          })
          .then((data) => {
            setEntry(data.data.results);
            setSumEntry(data.data.sum);
          })
          .catch(() => null)
          .finally(removeLoading);
      }
    },
    [schedule],
  );

  useEffect(() => {
    if (!schedule) return;
    addLoading();
    Promise.all([
      hospitalRepository.group({ scheduleId: schedule.id }).then((data) => {
        setGroups(data.data.results);
      }),
      masterRepository
        .university()
        .then((data) => setUniversities(data.data.results)),
    ])
      .catch(() => null)
      .finally(removeLoading);
    if (user?.authId === AuthType.University && user.universityId) {
      getEntry(user.universityId).then();
    }
  }, [schedule, user, getEntry]);

  const onChange = useCallback(
    (areaId: number, hospitalId: number, period: string, num: number) => {
      if (!schedule) return;
      if (!entry.hasOwnProperty(areaId)) {
        entry[areaId] = {};
      }
      if (!entry[areaId].hasOwnProperty(hospitalId)) {
        entry[areaId][hospitalId] = {
          scheduleId: schedule.id,
          secondCount: 0,
          thirdCount: 0,
          fourthCount: 0,
        };
      }
      setEntry({
        ...entry,
        [areaId]: {
          ...entry[areaId],
          [hospitalId]: { ...entry[areaId][hospitalId], [period]: num },
        },
      });
    },
    [entry, schedule],
  );

  const onUniversitySelect = useCallback(
    async (universityId?: number) => {
      if (universityId) await getEntry(universityId);
      else setEntry({});
    },
    [getEntry],
  );

  const disabled = useMemo(() => {
    if (user) {
      if (user.authId === AuthType.Hospital) return true;
    }
    if (!schedule || schedule.hospitalEntry.length < 2) return true;
    const now = dayjs().set('second', 0).set('milliseconds', 0);
    if (
      0 >= schedule.hospitalEntry[0].diff(now) &&
      0 <= schedule.hospitalEntry[1].diff(now)
    )
      return false;
    return !(
      0 >= schedule.hospitalReentry[0].diff(now) &&
      0 <= schedule.hospitalReentry[1].diff(now)
    );
  }, [schedule]);

  const update = useCallback(async () => {
    if (!schedule || !universityId) return;
    addLoading();
    await hospitalRepository
      .postEntry({
        scheduleId: schedule?.id,
        universityId,
        entries: entry,
      })
      .then(() => createMessage('登録しました。', 'info'))
      .catch(() => null)
      .finally(removeLoading);
  }, [entry, schedule, universityId, user]);

  return {
    groups,
    entry,
    onChange,
    universities,
    schedule,
    user,
    disabled,
    onUniversitySelect,
    universityId,
    update,
    sumEntry,
  };
};
