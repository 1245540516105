import { FC, memo, useState } from 'react';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';
import { HospitalEntryRow } from 'src/components/Organisms/Hospital/HospitalEntryRow';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { GroupHospitalEntity } from 'src/domain/group.hospital.entity';
import { EntryEntity } from 'src/domain/entry.entity';
type Props = {
  group: GroupHospitalEntity;
  entry: { [key: number]: EntryEntity };
  sumEntry: { [key: number]: EntryEntity };
  onChange: (
    areaId: number,
    hospitalId: number,
    period: string,
    num: number,
  ) => void;
  disabled: boolean;
};

export const HospitalEntryList: FC<Props> = memo((props) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <Flex vertical={true} style={{ alignSelf: 'stretch' }} align={'flex-start'}>
      <Flex
        vertical={false}
        align={'center'}
        justify={'space-between'}
        style={{
          height: '3rem',
          padding: '0 16px',
          background: '#000000',
          alignSelf: 'stretch',
          margin: 1,
        }}
        onClick={() => setOpen(isOpen ? false : true)}
      >
        <Typography.Title
          level={3}
          style={{
            color: '#FFFFFF',
            margin: 0,
            padding: 0,
          }}
        >
          {props.group.areaName}
        </Typography.Title>
        <Typography.Title
          level={5}
          style={{
            color: '#FFFFFF',
            margin: 0,
            padding: 0,
          }}
        >
          {/*props.group.hospitals.map((h) => props.entry?.[h.id]?.secondCount)*/}
          {' (小計: '}
          {props.group.hospitals
            .map((h) => props.entry?.[h.id]?.secondCount ?? 0)
            .reduce((i, j) => i + j, 0) + ' '}
          {props.group.hospitals
            .map((h) => props.entry?.[h.id]?.thirdCount ?? 0)
            .reduce((i, j) => i + j, 0) + ' '}
          {props.group.hospitals
            .map((h) => props.entry?.[h.id]?.fourthCount ?? 0)
            .reduce((i, j) => i + j, 0)}
          {' ) '}
        </Typography.Title>
        {!isOpen && (
          <DownOutlined
            style={{ color: '#FFFFFF', fontSize: '24px' }}
            onClick={() => setOpen(true)}
          />
        )}
        {isOpen && (
          <UpOutlined
            style={{ color: '#FFFFFF', fontSize: '24px' }}
            onClick={() => setOpen(false)}
          />
        )}
      </Flex>

      <Flex
        vertical={false}
        align={'flex-start'}
        justify={'flex-end'}
        style={{
          borderLeft: '1px solid #000000',
          display: isOpen ? 'inherit' : 'none',
          alignSelf: 'stretch',
        }}
      >
        <ColumnStyle vertical={false} justify={'center'}>
          第2期
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'}>
          第3期
        </ColumnStyle>
        <ColumnStyle vertical={false} justify={'center'}>
          第4期
        </ColumnStyle>
      </Flex>
      {props.group.hospitals.map((h, i) => (
        <HospitalEntryRow
          key={i}
          areaId={props.group.areaId}
          hospital={h}
          entry={props.entry?.[h.id]}
          sumEntry={props.sumEntry?.[h.id]}
          isOpen={isOpen}
          onChange={props.onChange}
          disabled={props.disabled}
        />
      ))}
    </Flex>
  );
});

const ColumnStyle = styled(Flex)`
  background: #000000;
  color: #ffffff;
  width: 160px;
  padding: 8px;
  border: 1px solid #ffffff;
`;
