import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import {
  HospitalCollectSearchBox,
  HospitalCollectTable,
} from 'src/components/Organisms';
import styled from 'styled-components';
import { useHospitalCollectHooks } from 'src/hooks/pages/use.hospital.collect.hooks';
export const HospitalCollect: FC = memo(() => {
  const {
    schedules,
    areas,
    hospitals,
    search,
    download,
    universities,
    footer,
  } = useHospitalCollectHooks();
  return (
    <Default
      title={'病院実習集計結果'}
      isSelect={1}
      contents={
        <>
          地区名を選択すると病院別のエントリー状況が表示されます。　
          <br />
          複数選択が可能。指定しない場合は全て。
        </>
      }
    >
      <HospitalCollectSearchBox
        schedules={schedules}
        areas={areas}
        onSearch={search}
        onDownload={download}
      />
      <RootStyle>
        {footer && (
          <HospitalCollectTable
            universities={universities}
            entries={hospitals}
            summery={footer}
          />
        )}
      </RootStyle>
    </Default>
  );
});

const RootStyle = styled.div`
  width: calc(100vw - 64px);
`;
