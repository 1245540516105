export const checkCsv = (
  columns: string[],
  data: string[][],
): { err: string[]; colMap: { [key: string]: number } } => {
  const err: string[] = [];
  if (data.length === 0) {
    err.push('データがありません');
    return { err, colMap: {} };
  }
  const colMap: { [key: string]: number } = {};
  columns.forEach((column) => {
    if (!data[0].includes(column)) {
      err.push(`${column}がヘッダー項目にありません`);
    }
  });
  if (err.length > 0) {
    return { err, colMap: {} };
  }
  if (data.length === 1) {
    err.push('データがありません');
    return { err, colMap: {} };
  }
  data[0].forEach((column, index) => {
    if (columns.includes(column)) {
      colMap[column] = index;
    }
  });
  return { err, colMap };
};
