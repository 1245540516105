import { FormEvent, useCallback, useContext, useEffect, useState } from 'react';
import { hospitalRepository } from 'src/service/hospital.repository';
import { HospitalEntity } from 'src/domain/hospital.entity';
import { masterRepository } from 'src/service/master.repository';
import { AreaEntity } from 'src/domain/area.entity';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { saveAs } from 'file-saver';
import { pharmacyRepository } from 'src/service/pharmacy.repository';
import { PharmacyEntity } from 'src/domain/pharmacy.entity';
import { MasterInfoSearchEntity } from 'src/domain/master.info.search.entity';
import { InfoContext } from 'src/providers/info.provider';

export const useMasterInfoHooks = () => {
  const [areas, setAreas] = useState<AreaEntity[]>([]);
  const [search, setSearch] = useState<MasterInfoSearchEntity>();
  const [hospital, setHospital] = useState<HospitalEntity[]>([]);
  const [pharmacy, setPharmacy] = useState<PharmacyEntity[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);
  const { schedule } = useContext(ScheduleContext);
  const { addLoading, removeLoading } = useContext(InfoContext);

  const load = useCallback(
    async (searchQuery: MasterInfoSearchEntity, page: number) => {
      if (!schedule) return;
      setSearch(searchQuery);
      setPage(page);
      addLoading();
      if (searchQuery.masterType === 1) {
        await hospitalRepository
          .list({
            scheduleId: schedule.id,
            areaId: searchQuery.areaId,
            page: page,
          })
          .then((data) => {
            setHospital(data.data.results);
            setTotal(data.data.count);
          })
          .catch(() => null)
          .finally(removeLoading);
      } else {
        await pharmacyRepository
          .list({
            scheduleId: schedule.id,
            areaId: searchQuery.areaId,
            page: page,
          })
          .then((data) => {
            setPharmacy(data.data.results);
            setTotal(data.data.count);
          })
          .catch(() => null)
          .finally(removeLoading);
      }
    },
    [schedule?.id],
  );

  const onSearch = useCallback(
    async (searchQuery: MasterInfoSearchEntity) => {
      await load(searchQuery, 1);
    },
    [load],
  );
  const onExportHospital = useCallback(
    async (e: FormEvent) => {
      if (!schedule) return;
      addLoading();
      await hospitalRepository
        .exportExcel({ scheduleId: schedule?.id })
        .then((data) => {
          const blob = new Blob([data.data], {
            type: data.data.type,
          });
          saveAs(blob, `病院一覧.xlsx`);
        })
        .catch(() => null)
        .finally(removeLoading);
      e.preventDefault();
    },
    [schedule?.id],
  );
  const onExportPharmacy = useCallback(
    async (e: FormEvent) => {
      if (!schedule) return;
      addLoading();
      await pharmacyRepository
        .exportExcel({ scheduleId: schedule?.id })
        .then((data) => {
          const blob = new Blob([data.data], {
            type: data.data.type,
          });
          saveAs(blob, `薬局一覧.xlsx`);
        })
        .catch(() => null)
        .finally(removeLoading);
      e.preventDefault();
    },
    [schedule],
  );

  useEffect(() => {
    addLoading();
    masterRepository
      .area()
      .then((data) => setAreas(data.data.results))
      .finally(removeLoading);
  }, []);

  const onPage = useCallback(
    async (page: number) => {
      if (search) await load(search, page);
    },
    [load, search],
  );
  return {
    areas,
    onSearch,
    onExportPharmacy,
    onExportHospital,
    search,
    hospital,
    pharmacy,
    onPage,
    page,
    total,
  };
};
