import { FC, memo } from 'react';
import { HomeMenu } from 'src/components/Molecules';
import { Row } from 'antd';
import { AuthType } from 'src/common/types';
type Props = {
  auth: AuthType;
};

export const HomeMenus: FC<Props> = memo((props) => {
  return (
    <Row gutter={[0, 16]} justify={'center'}>
      {props.auth !== AuthType.Hospital && (
        <HomeMenu
          title={'病院実習'}
          items={[
            {
              name: '病院エントリー',
              link: '/hospital/entry',
            },
            {
              name: '病院実習集計結果',
              link: '/hospital/collect',
            },
          ]}
        />
      )}
      {props.auth != AuthType.Hospital && (
        <HomeMenu
          title={'薬局実習'}
          items={[
            {
              name: '薬局エントリー',
              link: '/pharmacy/entry',
            },
            {
              name: '薬局実習集計結果',
              link: '/pharmacy/collect',
            },
          ]}
        />
      )}
      {props.auth != AuthType.Hospital && (
        <HomeMenu
          title={'実習施設検索'}
          items={[
            {
              name: '病院薬局情報',
              link: '/master/info',
            },
          ]}
        />
      )}
      {[AuthType.Admin].includes(props.auth) && (
        <HomeMenu
          title={'病院マスター'}
          items={[
            {
              name: '病院マスター登録',
              link: '/hospital/master',
            },
          ]}
        />
      )}
      {props.auth === AuthType.Admin && (
        <HomeMenu
          title={'事務局専用メニュー'}
          items={[
            {
              name: '薬局マスター登録および調査結果アップロード',
              link: '/pharmacy/master',
            },
            {
              name: 'ログインアカウント管理',
              link: '/accounts',
            },
            {
              name: 'エントリースケジュール管理',
              link: '/schedule',
            },
            {
              name: 'お知らせ管理',
              link: '/news',
            },
          ]}
        />
      )}
    </Row>
  );
});
