import { useCallback, useContext, useEffect, useState } from 'react';
import { AccountPasswordEntity } from 'src/domain/account.entity';
import { AuthType } from 'src/common/types';
import { accountRepository } from 'src/service/account.repository';
import { useNavigate, useParams } from 'react-router-dom';
import { InfoContext } from 'src/providers/info.provider';

export const useAccountPasswordHooks = () => {
  const [account, setAccount] = useState<AccountPasswordEntity>({
    userId: 0,
    authId: AuthType.University,
    name: '',
    accountId: '',
    password: '',
  });
  const [step, setStep] = useState(0);
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);

  useEffect(() => {
    addLoading();
    accountRepository
      .get(Number(params.id))
      .then((data) => {
        setAccount({
          userId: data.data.userId,
          accountId: data.data.accountId,
          authId: data.data.authId,
          name: data.data.name,
          password: '',
        });
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);

  const onCreate = useCallback(async () => {
    addLoading();
    await accountRepository
      .update({
        userId: account.userId,
        password: account.password,
      })
      .then(() => {
        createMessage('パスワードを変更しました', 'info');
        navigate('/accounts');
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [account]);

  const onBack = useCallback(() => {
    setStep(0);
  }, []);

  const onNext = useCallback(
    (values: AccountPasswordEntity) => {
      setAccount({ ...values, userId: Number(params.id) });
      setStep(1);
    },
    [params.id],
  );
  return { account, onBack, onNext, onCreate, step };
};
