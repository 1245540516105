import { useCallback, useContext, useEffect, useState } from 'react';
import { ScheduleEntity } from 'src/domain/schedule.entity';
import { scheduleRepository } from 'src/service/schedule.repository';
import { useNavigate } from 'react-router-dom';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { InfoContext } from 'src/providers/info.provider';

export const useScheduleEditHooks = () => {
  const { schedule, load } = useContext(ScheduleContext);
  const [values, setValues] = useState(schedule);

  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  useEffect(() => {
    if (schedule) setValues(schedule);
  }, [schedule]);

  const onNext = useCallback((values: ScheduleEntity) => {
    setValues(values);
    setStep(1);
  }, []);
  const onBack = useCallback(() => {
    setStep(0);
  }, []);

  const onCreate = useCallback(async () => {
    if (!values || !schedule) return;
    addLoading();
    await scheduleRepository
      .update({
        id: schedule.id,
        name: values.name,
        hospitalEntry: values.hospitalEntry.map((d) => d.toDate()),
        hospitalAdjustment: values.hospitalAdjustment.map((d) => d.toDate()),
        hospitalReentry: values.hospitalReentry.map((d) => d.toDate()),
        pharmacyMaster: values.pharmacyMaster.map((d) => d.toDate()),
        pharmacyEntry: values.pharmacyEntry.map((d) => d.toDate()),
        pharmacyAdjustment: values.pharmacyAdjustment.map((d) => d.toDate()),
      })
      .then(async () => {
        createMessage('スケジュールを更新しました。', 'info');
        await load().then(() => navigate('/schedule'));
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [values]);

  return { values, onNext, onBack, step, onCreate };
};
