import { FC, memo } from 'react';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';
import { LoginForm } from 'src/components/Organisms/Account/LoginForm';
import { useLoginHooks } from 'src/hooks/pages/use.login.hooks';

export const Login: FC = memo(() => {
  const { login } = useLoginHooks();
  return (
    <BodyStyle>
      <RootStyle vertical={true} gap={32} align={'center'}>
        <Typography.Title level={3}>実習エントリーシステム</Typography.Title>
        <LoginForm onLogin={login} />
      </RootStyle>
    </BodyStyle>
  );
});

const RootStyle = styled(Flex)`
  background: #d4d2d5;
  padding: 32px;
  border-radius: 16px;
  h3 {
    margin: 0;
  }
`;

const BodyStyle = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
