import { FC, memo } from 'react';
import { Flex } from 'antd';
import { Default } from 'src/components/Layouts/default';
import { useAccountPasswordHooks } from 'src/hooks/pages/use.account.password.hooks';
import { AccountPasswordForm } from 'src/components/Organisms/Account/AccountPasswordForm';
import { AccountPasswordConfirm } from 'src/components/Organisms/Account/AccountPasswordConfirm';

export const AccountPassword: FC = memo(() => {
  const { account, onCreate, onBack, onNext, step } = useAccountPasswordHooks();
  return (
    <Default
      title={`パスワード変更`}
      isSelect={5}
      contents={
        <Flex vertical={true} gap={8}>
          <div style={{ color: 'red' }}>※印は入力必須項目です。</div>
        </Flex>
      }
    >
      {step === 0 && account.userId > 0 && (
        <AccountPasswordForm
          account={account}
          onConfirm={onNext}
          label={'確認'}
        />
      )}
      {step === 1 && (
        <AccountPasswordConfirm
          account={account}
          onBack={onBack}
          onUpdate={onCreate}
        />
      )}
    </Default>
  );
});
