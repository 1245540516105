import { FC, memo } from 'react';
import { Flex } from 'antd';
import { NewsEntity } from 'src/domain/news.entity';

type Props = {
  news: NewsEntity;
};
export const NewsRow: FC<Props> = memo((props) => {
  return (
    <Flex vertical={false} gap={'middle'} align={'start'}>
      <Flex vertical={false}>{props.news.date.format('YYYY-MM-DD')}</Flex>
      <Flex vertical={false}>{props.news.content}</Flex>
    </Flex>
  );
});
