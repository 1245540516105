import { useContext, useEffect, useState } from 'react';
import { GroupHospitalEntity } from 'src/domain/group.hospital.entity';
import { hospitalRepository } from 'src/service/hospital.repository';
import { ScheduleContext } from 'src/providers/schedule.provider';
import { InfoContext } from 'src/providers/info.provider';

export const useHospitalMasterSelectHooks = () => {
  const [groups, setGroup] = useState<GroupHospitalEntity[]>([]);
  const { schedule, user } = useContext(ScheduleContext);
  const { addLoading, removeLoading } = useContext(InfoContext);
  useEffect(() => {
    if (!schedule || !user) return;
    addLoading();
    hospitalRepository
      .group({ scheduleId: schedule.id, hospitalId: user?.hospitalId })
      .then((data) => {
        setGroup(data.data.results);
      })
      .catch(() => null)
      .finally(removeLoading);
  }, [schedule, user]);
  return { groups };
};
