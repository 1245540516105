import { useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ScheduleContext } from 'src/providers/schedule.provider';

export const useScheduleDetailHooks = () => {
  const { schedule } = useContext(ScheduleContext);
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const onEdit = useCallback(() => {
    if (schedule) navigate(`/schedule/edit`);
  }, [schedule]);

  const onCreate = useCallback(() => {
    navigate(`/schedule/create`);
  }, []);

  return { schedule, onEdit, isOpen, setOpen, onCreate };
};
