import { FC, memo, ReactNode, useState } from 'react';
import { Flex } from 'antd';
import styled from 'styled-components';
import { SubMenus } from 'src/components/Organisms/Common/SubMenus';
import { useNavigate } from 'react-router-dom';

type Props = {
  name: ReactNode;
  link?: string;
  items?: Props[];
  isSelect: boolean;
};
export const Menu: FC<Props> = memo((props) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  return (
    <RootStyle
      $isSelect={props.isSelect}
      vertical={true}
      justify={'center'}
      align={'center'}
      onClick={() => {
        props.link ? navigate(props.link) : null;
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {props.name}
      {props.items && <SubMenus isOpen={hover} items={props.items} />}
    </RootStyle>
  );
});

const RootStyle = styled(Flex)<{ $isSelect: boolean }>`
  width: 160px;
  height: 60px;
  border: 1px solid rgba(0, 0, 0, 0.7);
  line-height: initial;
  padding: 8px 0;
  align-self: stretch;
  text-align: center;
  position: relative;
  background: ${(props) => (props.$isSelect ? '#000000' : '#FFFFFF')};
  color: ${(props) => (!props.$isSelect ? '#000000' : '#FFFFFF')};
  &:hover {
    background: #000000;
    color: #ffffff;
  }
`;
