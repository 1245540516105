import { useCallback, useContext, useEffect, useState } from 'react';
import { NewsEntity, NewsSearchEntity } from 'src/domain/news.entity';
import { useNavigate } from 'react-router-dom';
import { newsRepository } from 'src/service/news.repository';
import { dateApiFormat } from 'src/common/format';
import dayjs from 'dayjs';
import { InfoContext } from 'src/providers/info.provider';

export const useNewsListHooks = () => {
  const [news, setNews] = useState<NewsEntity[]>([]);
  const [query, setQuery] = useState<NewsSearchEntity>({
    date: [],
  });

  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(0);

  const navigate = useNavigate();
  const { addLoading, removeLoading, createMessage } = useContext(InfoContext);
  const load = useCallback(async (values: NewsSearchEntity, page: number) => {
    setPage(page);
    setQuery(values);
    addLoading();
    await newsRepository
      .list({
        page: page,
        content: values.content,
        startDate: values.start?.format(dateApiFormat),
        endDate: values.end?.format(dateApiFormat),
        take: 50,
      })
      .then((data) => {
        setNews(
          data.data.results.map((news) => {
            return {
              id: news.id,
              content: news.content,
              date: dayjs(news.date),
              invalid: news.invalid,
            };
          }),
        );
        setTotal(data.data.count);
      })
      .catch(() => null)
      .finally(removeLoading);
  }, []);

  const onSearch = useCallback(
    async (values: NewsSearchEntity) => {
      await load(values, 1);
    },
    [load],
  );

  useEffect(() => {
    onSearch(query).then();
  }, [query]);

  const onMove = useCallback((id: number) => {
    navigate(`/news/edit/${id}`);
  }, []);

  const onStop = useCallback(
    (news: NewsEntity) => {
      addLoading();
      newsRepository
        .update({
          id: news.id,
          content: news.content,
          date: news.date.format(dateApiFormat),
          invalid: news.invalid === 0 ? 1 : 0,
        })
        .then(async () => {
          createMessage('お知らせを削除しました。', 'info');
          await onSearch(query);
        })
        .catch(() => null)
        .finally(removeLoading);
    },
    [query],
  );

  const onAdd = useCallback(() => {
    navigate(`/news/create`);
  }, []);

  const onPage = useCallback(
    async (page: number) => {
      await load(query, page);
    },
    [load, query],
  );

  return { onSearch, news, onMove, onStop, onAdd, total, page, onPage };
};
