import { FC, memo } from 'react';
import { Flex, Form, Input, Select } from 'antd';
import { AccountCreateEntity } from 'src/domain/account.entity';
import { SubmitButton } from 'src/components/Atoms/SubmitButton';
import { AuthType } from 'src/common/types';
type Props = {
  account: AccountCreateEntity;
  onConfirm: (values: AccountCreateEntity) => void;
  label: string;
};
export const AccountForm: FC<Props> = memo((props) => {
  const [form] = Form.useForm<AccountCreateEntity>();
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '600px' }}
      initialValues={props.account}
      onFinish={props.onConfirm}
    >
      <Flex
        vertical={true}
        style={{ border: '1px solid #00000070', padding: '16px' }}
      >
        <Form.Item label={'権限'} name={'authId'}>
          <Select placeholder={'権限'} disabled={true}>
            <Select.Option value={1}>管理者</Select.Option>
            <Select.Option value={2}>病院</Select.Option>
            <Select.Option value={3}>大学</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={'ログインID'}
          required={true}
          name={'accountId'}
          rules={[{ required: true, message: '入力してください' }]}
        >
          <Input />
        </Form.Item>
        {props.account.authId === AuthType.University && (
          <Flex vertical={true}>
            <Form.Item
              label={'大学名'}
              required={true}
              name={'universityName'}
              rules={[
                {
                  required: props.account.authId === AuthType.University,
                  message: '入力してください',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={'省略大学名'}
              required={true}
              name={'universityOmissionName'}
              rules={[
                {
                  required: props.account.authId === AuthType.University,
                  message: '入力してください',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Flex>
        )}
        <Form.Item
          label={'パスワード'}
          required={true}
          name={'password'}
          rules={[
            { required: true, message: '入力してください' },
            { min: 6, message: '最低6文字以上入力してください' },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={'パスワード確認'}
          required={true}
          dependencies={['password']}
          name={'passwordConfirm'}
          rules={[
            { required: true, message: '入力してください' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('パスワードが一致していません'),
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Flex>
      <Flex justify={'center'} style={{ width: '100%', margin: '16px' }}>
        <SubmitButton form={form}>{props.label}</SubmitButton>
      </Flex>
    </Form>
  );
});
