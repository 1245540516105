import { FC, memo, ReactNode, useCallback } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import styled from 'styled-components';

type Props = {
  handleChange: (files: File[]) => void;
  accept: Accept;
  multiple: boolean;
  label: ReactNode;
  disabled: boolean;
};

export const FileDragDrop: FC<Props> = memo((props) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      props.handleChange(acceptedFiles);
    },
    [props.handleChange],
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: props.accept,
  });

  return (
    <div {...getRootProps()}>
      <input
        {...getInputProps()}
        multiple={props.multiple}
        disabled={props.disabled}
      />
      <RootStyle $disabled={props.disabled}>{props.label}</RootStyle>
    </div>
  );
});

const RootStyle = styled.div<{ $disabled: boolean }>`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 16px;
  background: #f5f3f7;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  opacity: ${(props) => (props.$disabled ? 0.3 : 1.0)};
  &:hover {
    opacity: ${(props) => (props.$disabled ? 0.3 : 0.7)};
  }
`;
