import { CSSProperties, FC, PropsWithChildren } from 'react';
import { Button } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import styled from 'styled-components';

interface SubmitButtonProps {
  disabled?: boolean;
  size?: SizeType;
  style?: CSSProperties;
  onClick?: () => void;
}

export const SecondaryButton: FC<PropsWithChildren<SubmitButtonProps>> = ({
  disabled,
  size,
  onClick,
  style,
  children,
}) => {
  return (
    <RootStyle
      type="primary"
      disabled={disabled}
      size={size}
      onClick={onClick}
      style={style}
    >
      {children}
    </RootStyle>
  );
};

const RootStyle = styled(Button)`
  border: 1px solid #000000;
  background: #ffffff;
  color: #000000;
  box-shadow: none;
`;
