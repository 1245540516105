import { FC, memo, useCallback } from 'react';
import { Flex, Input } from 'antd';
import styled from 'styled-components';

type Props = {
  capacity: number;
  entry?: number;
  sum: number;
  areaId: number;
  hospitalId: number;
  period: string;
  onChange: (
    areaId: number,
    hospitalId: number,
    period: string,
    num: number,
  ) => void;
  disabled: boolean;
};
export const HospitalEntryEditCell: FC<Props> = memo((props) => {
  const onChange = useCallback(
    (period: string, num: string) => {
      props.onChange(
        props.areaId,
        props.hospitalId,
        period,
        isNaN(Number(num)) ? 0 : Number(num),
      );
    },
    [props.areaId, props.hospitalId, props.onChange],
  );
  return (
    <Flex vertical={true} style={{ width: '160px' }}>
      <CellHeaderStyle
        vertical={false}
        justify={'flex-end'}
        align={'center'}
        style={{ padding: '2px 28px' }}
      >
        {props.capacity}
      </CellHeaderStyle>
      <CellEditStyle
        vertical={false}
        justify={'flex-end'}
        $isError={props.capacity < (props.entry ?? 0) + props.sum}
      >
        <Input
          value={props.entry ?? 0}
          type={'number'}
          min={0}
          disabled={props.disabled}
          onChange={(e) => onChange(props.period, e.target.value)}
        />
      </CellEditStyle>
      <CellHeaderStyle
        vertical={false}
        justify={'flex-end'}
        align={'center'}
        style={{ padding: '2px 28px' }}
      >
        {props.sum + (props.entry ?? 0)}
      </CellHeaderStyle>
    </Flex>
  );
});

const CellStyle = styled(Flex)`
  border: 1px solid #000000;
  height: 39px;
`;

const CellHeaderStyle = styled(CellStyle)`
  background: #000000;
  color: #ffffff;
  border-color: #ffffff;
`;

const CellEditStyle = styled(CellStyle)<{ $isError: boolean }>`
  height: 39px;
  input {
    text-align: end;
    background: ${(props) => (props.$isError ? '#FF0000' : '#fff385')};
    &:disabled {
      background: ${(props) => (props.$isError ? '#FF0000' : undefined)};
    }
  }
`;
