import { FC, memo } from 'react';
import { Flex, Form, Select } from 'antd';
import { AreaEntity } from 'src/domain/area.entity';
import { useForm } from 'antd/es/form/Form';
import { MasterInfoSearchEntity } from 'src/domain/master.info.search.entity';
import { SubmitButton } from 'src/components/Atoms/SubmitButton';

type Props = {
  areas: AreaEntity[];
  onSearch: (values: MasterInfoSearchEntity) => void;
};

export const SearchBox: FC<Props> = memo((props) => {
  const [form] = useForm<MasterInfoSearchEntity>();
  return (
    <Flex vertical={true} gap={16} align={'center'}>
      <Form layout="vertical" form={form} onFinish={props.onSearch}>
        <Flex vertical={false} gap={16} justify={'center'}>
          <Form.Item label="病院・薬局" name="masterType" required={true}>
            <Select placeholder={'選択してください'}>
              <Select.Option value={1}>病院</Select.Option>
              <Select.Option value={2}>薬局</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="所属地区" name="areaId" required={true}>
            <Select placeholder={'選択してください'}>
              {props.areas.map((area) => (
                <Select.Option key={area.id} value={area.id}>
                  {area.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Flex>
        <Flex vertical={true} align={'center'}>
          <SubmitButton form={form}>一覧を表示する</SubmitButton>
        </Flex>
      </Form>
    </Flex>
  );
});
