import { FC, memo } from 'react';
import { Menu } from 'src/components/Molecules';
import { Flex } from 'antd';
import { AuthType } from 'src/common/types';

type Props = {
  select: number;
  auth: number;
};
export const Menus: FC<Props> = memo((props) => {
  return (
    <Flex vertical={false} align={'center'} justify={'center'}>
      <Menu name={'ホーム'} isSelect={props.select === 0} link={'/'} />
      {props.auth != AuthType.Hospital && (
        <Menu
          name={<>病院実習</>}
          isSelect={props.select === 1}
          items={[
            {
              name: '病院エントリー',
              link: '/hospital/entry',
              isSelect: false,
            },
            {
              name: '病院実習集計結果',
              link: '/hospital/collect',
              isSelect: false,
            },
          ]}
        />
      )}
      {props.auth != AuthType.Hospital && (
        <Menu
          name={<>薬局実習</>}
          isSelect={props.select === 2}
          items={
            props.auth === AuthType.Hospital
              ? [
                  {
                    name: '薬局実習集計結果',
                    link: '/pharmacy/collect',
                    isSelect: false,
                  },
                ]
              : [
                  {
                    name: '薬局エントリー',
                    link: '/pharmacy/entry',
                    isSelect: false,
                  },
                  {
                    name: '薬局実習集計結果',
                    link: '/pharmacy/collect',
                    isSelect: false,
                  },
                ]
          }
        />
      )}
      {props.auth != AuthType.Hospital && (
        <Menu
          name={
            <>
              病院・薬局
              <br />
              情報
            </>
          }
          link={'/master/info'}
          isSelect={props.select === 3}
        />
      )}
      {[AuthType.Admin].includes(props.auth) && (
        <Menu
          name={
            <>
              病院マスター
              <br />
              登録
            </>
          }
          link={'/hospital/master'}
          isSelect={props.select === 4}
        />
      )}
      {props.auth == AuthType.Admin && (
        <Menu
          name={<>事務局専用</>}
          isSelect={props.select === 5}
          items={[
            {
              name: '薬局マスター登録および調整結果アップロード',
              link: '/pharmacy/master',
              isSelect: false,
            },
            {
              name: 'ログインアカウント',
              link: '/accounts',
              isSelect: false,
            },
            {
              name: 'お知らせ管理',
              link: '/news',
              isSelect: false,
            },
            {
              name: 'スケジュール管理',
              link: '/schedule',
              isSelect: false,
            },
          ]}
        />
      )}
    </Flex>
  );
});
