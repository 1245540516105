import { FC, memo } from 'react';
import { Button, Flex, Form } from 'antd';
import { AccountPasswordEntity } from 'src/domain/account.entity';
import { AuthName } from 'src/common/types';

type Props = {
  account: AccountPasswordEntity;
  onUpdate: () => void;
  onBack: () => void;
};
export const AccountPasswordConfirm: FC<Props> = memo((props) => {
  return (
    <Form
      layout="vertical"
      style={{ width: '600px' }}
      onFinish={props.onUpdate}
    >
      <Flex
        vertical={true}
        style={{ border: '1px solid #00000070', padding: '16px' }}
      >
        <Flex vertical={true}>
          <Form.Item label={'権限'}>{AuthName[props.account.authId]}</Form.Item>
          <Form.Item label={'ログインID'}>{props.account.accountId}</Form.Item>
          <Form.Item label={AuthName[props.account.authId]}>
            {props.account.name}
          </Form.Item>
          <Form.Item label={'パスワード'} required={true}>
            ******
          </Form.Item>
        </Flex>
      </Flex>
      <Flex
        justify={'center'}
        style={{ width: '100%', margin: '16px' }}
        gap={32}
      >
        <Button onClick={props.onBack}>戻る</Button>
        <Button htmlType={'submit'} type="primary">
          変更
        </Button>
      </Flex>
    </Form>
  );
});
