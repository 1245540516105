import { FC, memo } from 'react';
import { Button, Divider, Flex, Form } from 'antd';
import { HospitalEntity } from 'src/domain/hospital.entity';
import styled from 'styled-components';
import { AccountCreateEntity } from 'src/domain/account.entity';
import { AuthName } from 'src/common/types';
import { GroupEntity } from 'src/domain/group.entity';

type Props = {
  account?: AccountCreateEntity;
  hospital: HospitalEntity;
  group?: GroupEntity;
  isEdit: boolean;
  onUpdate: () => void;
  onBack: () => void;
};

export const HospitalConfirm: FC<Props> = memo((props) => {
  return (
    <Form
      layout="vertical"
      style={{ width: '600px' }}
      onFinish={props.onUpdate}
    >
      <Flex
        vertical={true}
        style={{ border: '1px solid #00000070', padding: '16px' }}
      >
        <Flex vertical={true}>
          {props.account && (
            <Flex vertical={true}>
              <Form.Item label={'権限'} required={true} name={'auth'}>
                {AuthName[props.account.authId]}
              </Form.Item>
              <Form.Item label={'ログインID'} required={true} name={'loginId'}>
                {props.account.accountId}
              </Form.Item>
              <Form.Item label={'パスワード'} required={true} name={'password'}>
                ******
              </Form.Item>
            </Flex>
          )}
          <Form.Item label={'地域'} required={true} name={'areaId'}>
            {props.group?.area.name}
          </Form.Item>
          <Form.Item label={'グループ番号'} required={true} name={'groupId'}>
            {props.group?.name}
          </Form.Item>
          <Form.Item label={'施設番号'} required={true} name={'facilityNumber'}>
            {props.hospital.facilityNumber}
          </Form.Item>
          <Form.Item label={'病院コード'} required={true} name={'hospitalId'}>
            {props.hospital.hospitalNo}
          </Form.Item>
          <Form.Item label={'病院名'} required={true} name={'name'}>
            {props.hospital.name}
          </Form.Item>
          <Form.Item label={'病院ふりがな'} required={true} name={'kana'}>
            {props.hospital.kana}
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            実習生定員
            <Divider
              orientationMargin={0}
              style={{ borderColor: '#000000', margin: 0 }}
            />
          </SubTitleStyle>
          <Flex vertical={false} gap={32}>
            <Form.Item
              label={'2期'}
              required={true}
              name={['capacity', 2]}
              style={{ textAlign: 'center' }}
            >
              {props.hospital.capacity.secondCount}
            </Form.Item>
            <Form.Item
              label={'3期'}
              required={true}
              name={['capacity', 3]}
              style={{ textAlign: 'center' }}
            >
              {props.hospital.capacity.thirdCount}
            </Form.Item>
            <Form.Item
              label={'4期'}
              required={true}
              name={['capacity', 4]}
              style={{ textAlign: 'center' }}
            >
              {props.hospital.capacity.fourthCount}
            </Form.Item>
          </Flex>
          <Form.Item label={'備考（200文字まで）'} name={'note'}>
            {props.hospital.capacity.note ?? 'なし'}
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            病院情報
            <Divider orientationMargin={0} />
          </SubTitleStyle>
          <Form.Item name={'isCooperatingHospital'} valuePropName="checked">
            協力病院の{props.hospital.isCooperatingHospital ? 'あり' : 'なし'}
          </Form.Item>
          <Form.Item
            label={'同上有の場合の施設名'}
            required={props.hospital.isCooperatingHospital}
            name={'cooperatingHospital'}
          >
            {props.hospital.cooperatingHospital ?? 'なし'}
          </Form.Item>
          <Form.Item label={'院長名'} required={true} name={'director'}>
            {props.hospital.director}
          </Form.Item>
          <Form.Item
            label={'薬局長・部科長名'}
            required={true}
            name={'headPharmacyDepartment'}
          >
            {props.hospital.headPharmacyDepartment}
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            実習場所住所
            <Divider
              orientationMargin={0}
              style={{ borderColor: '#000000', margin: 0 }}
            />
          </SubTitleStyle>
          <Form.Item
            label={'郵便番号（ハイフンなし）'}
            required={true}
            style={{ width: 'fit-content' }}
            name={'zipCode'}
          >
            {props.hospital.zipCode}
          </Form.Item>
          <Form.Item label={'住所'} required={true} name={'address'}>
            {props.hospital.address}
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            連絡先
            <Divider
              orientationMargin={0}
              style={{ borderColor: '#000000', margin: 0 }}
            />
          </SubTitleStyle>
          <Form.Item
            label={'電話番号（ハイフンなし）'}
            required={true}
            style={{ width: 'fit-content' }}
            name={'tel'}
          >
            {props.hospital.tel}
          </Form.Item>
          <Form.Item label={'メールアドレス'} required={true} name={'email'}>
            {props.hospital.email}
          </Form.Item>
          <Form.Item
            label={'担当部署名'}
            required={true}
            name={'departmentName'}
          >
            {props.hospital.departmentName}
          </Form.Item>
        </Flex>
      </Flex>
      <Flex
        justify={'center'}
        style={{ width: '100%', margin: '16px' }}
        gap={32}
      >
        <Button onClick={props.onBack}>戻る</Button>
        <Button htmlType={'submit'} type="primary">
          {props.isEdit ? '更新' : '登録'}
        </Button>
      </Flex>
    </Form>
  );
});

const SubTitleStyle = styled(Flex)`
  font-size: 22px;
  font-weight: bold;
`;
