import { FC, memo } from 'react';
import { Flex, Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  errors: string[];
};
export const PharmacyError: FC<Props> = memo((props) => {
  return (
    <Flex vertical={true} align={'start'} style={{ width: '100%' }}>
      <Typography.Title level={5}>更新結果</Typography.Title>
      <RootStyle vertical={true} gap={8}>
        {props.errors.map((err, i) => (
          <div key={i}>{err}</div>
        ))}
      </RootStyle>
    </Flex>
  );
});

const RootStyle = styled(Flex)`
  width: 100%;
  border: 1px solid #000000;
  padding: 16px;
`;
