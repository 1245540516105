import { FC, memo, useCallback, useState } from 'react';
import { Checkbox, Divider, Flex, Form, Input, Select } from 'antd';
import { HospitalEntity } from 'src/domain/hospital.entity';
import styled from 'styled-components';
import TextArea from 'antd/es/input/TextArea';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SubmitButton } from 'src/components/Atoms/SubmitButton';
import { SecondaryButton } from 'src/components/Atoms/SecondaryButton';
import { AreaEntity } from 'src/domain/area.entity';
import { GroupEntity } from 'src/domain/group.entity';

type Props = {
  hospital: HospitalEntity;
  areas: AreaEntity[];
  groups: GroupEntity[];
  isEdit: boolean;
  onConfirm: (values: HospitalEntity) => void;
  onBack?: () => void;
  setArea?: (areaId: number) => void;
};

export const HospitalForm: FC<Props> = memo((props) => {
  const [form] = Form.useForm<HospitalEntity>();
  const [isCooperatingHospital, setIsCooperatingHospital] = useState(
    props.hospital.isCooperatingHospital,
  );
  const onChecked = useCallback((e: CheckboxChangeEvent) => {
    setIsCooperatingHospital(e.target.checked);
  }, []);
  const setArea = useCallback(
    (areaId: number) => {
      form.setFieldValue('groupId', undefined);
      if (props.setArea) props.setArea(areaId);
    },
    [props.setArea],
  );
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '600px' }}
      initialValues={props.hospital}
      onFinish={props.onConfirm}
    >
      <Flex
        vertical={true}
        style={{ border: '1px solid #00000070', padding: '16px' }}
      >
        <Form.Item name="id" noStyle />
        <Form.Item name="invalid" noStyle />
        <Flex vertical={true}>
          <Form.Item
            label={'地区'}
            required={false}
            name={'areaId'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Select
              placeholder={'選択してください'}
              disabled={props.isEdit}
              onChange={setArea}
            >
              {props.areas.map((area) => (
                <Select.Option key={area.id} value={area.id}>
                  {area.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'グループ番号'}
            required={false}
            name={'groupId'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Select placeholder={'選択してください'} disabled={props.isEdit}>
              {props.groups.map((group) => (
                <Select.Option key={group.id} value={group.id}>
                  {group.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'施設番号'}
            required={false}
            name={'facilityNumber'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input disabled={props.isEdit} />
          </Form.Item>
          <Form.Item
            label={'病院コード'}
            required={false}
            name={'hospitalNo'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input disabled={props.isEdit} />
          </Form.Item>
          <Form.Item
            label={'病院名'}
            required={false}
            name={'name'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input disabled={props.isEdit} />
          </Form.Item>
          <Form.Item
            label={'病院ふりがな'}
            required={false}
            name={'kana'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input disabled={props.isEdit} />
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            実習生定員
            <Divider
              orientationMargin={0}
              style={{ borderColor: '#000000', margin: 0 }}
            />
          </SubTitleStyle>
          <Flex vertical={false} gap={8}>
            <Form.Item
              label={'2期'}
              required={true}
              name={['capacity', 'secondCount']}
              rules={[{ required: true, message: '入力してください' }]}
            >
              <Input
                style={{ textAlign: 'right' }}
                type={'number'}
                min={0}
                disabled={false}
              />
            </Form.Item>
            <Form.Item
              label={'3期'}
              required={true}
              name={['capacity', 'thirdCount']}
              rules={[{ required: true, message: '入力してください' }]}
            >
              <Input
                style={{ textAlign: 'right' }}
                type={'number'}
                min={0}
                disabled={false}
              />
            </Form.Item>
            <Form.Item
              label={'4期'}
              required={true}
              name={['capacity', 'fourthCount']}
              rules={[{ required: true, message: '入力してください' }]}
            >
              <Input
                style={{ textAlign: 'right' }}
                type={'number'}
                min={0}
                disabled={false}
              />
            </Form.Item>
          </Flex>
          <Form.Item
            label={'備考（200文字まで）'}
            name={['capacity', 'note']}
            rules={[{ max: 200, message: '200文字以下で入力してください' }]}
          >
            <TextArea />
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            病院情報
            <Divider
              orientationMargin={0}
              style={{ borderColor: '#000000', margin: 0 }}
            />
          </SubTitleStyle>
          <Form.Item name={'isCooperatingHospital'} valuePropName="checked">
            <Checkbox onChange={onChecked}>協力病院の有無</Checkbox>
          </Form.Item>
          <Form.Item
            label={'同上有の場合の施設名'}
            required={isCooperatingHospital}
            name={'cooperatingHospital'}
            rules={[
              { required: isCooperatingHospital, message: '入力してください' },
            ]}
          >
            <Input disabled={!isCooperatingHospital} />
          </Form.Item>
          <Form.Item
            label={'院長名'}
            required={true}
            name={'director'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'薬局長・部科長名'}
            required={true}
            name={'headPharmacyDepartment'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input />
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            実習場所住所
            <Divider
              orientationMargin={0}
              style={{ borderColor: '#000000', margin: 0 }}
            />
          </SubTitleStyle>
          <Form.Item
            label={'郵便番号（ハイフンなし）'}
            required={true}
            style={{ width: 'fit-content' }}
            name={'zipCode'}
            rules={[
              { required: true, message: '入力してください' },
              { pattern: /^\d+$/, message: '数字で入力してください' },
              { max: 7, message: '7桁で入力してください' },
              { min: 7, message: '7桁で入力してください' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={'住所'}
            required={true}
            name={'address'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input />
          </Form.Item>
        </Flex>
        <Flex vertical={true} gap={8}>
          <SubTitleStyle vertical={true}>
            連絡先
            <Divider
              orientationMargin={0}
              style={{ borderColor: '#000000', margin: 0 }}
            />
          </SubTitleStyle>
          <Form.Item
            label={'電話番号（ハイフンなし）'}
            required={true}
            style={{ width: 'fit-content' }}
            name={'tel'}
            rules={[
              { required: isCooperatingHospital, message: '入力してください' },
              {
                pattern: /^\d+$/,
                message: '数字で入力してください',
              },
              {
                max: 13,
                message: '13桁以内で入力してください',
              },
            ]}
          >
            <Input value={props.hospital.tel} type={'tel'} />
          </Form.Item>
          <Form.Item
            label={'メールアドレス'}
            required={true}
            name={'email'}
            rules={[
              {
                required: true,
                message: '入力してください',
              },
              {
                type: 'email',
                message: 'メールアドレスの形式で入力してください',
              },
            ]}
          >
            <Input type={'email'} />
          </Form.Item>
          <Form.Item
            label={'担当部署名'}
            required={true}
            name={'departmentName'}
            rules={[{ required: true, message: '入力してください' }]}
          >
            <Input />
          </Form.Item>
        </Flex>
      </Flex>
      <Flex
        justify={'center'}
        style={{ width: '100%', margin: '16px' }}
        gap={32}
      >
        {!props.isEdit && (
          <SecondaryButton onClick={props.onBack}>戻る</SecondaryButton>
        )}
        <SubmitButton form={form}>確認する</SubmitButton>
      </Flex>
      <Flex
        justify={'center'}
        style={{ width: '100%', margin: '16px' }}
        gap={32}
      >
        <div style={{ color: 'red' }}>
          ※「確認する」ボタンが有効にならない場合には「郵便番号」、「電話番号」と「メールアドレス」を再入力してください。
          <br />※ 指定できるメールアドレスは1件のみです。
        </div>
      </Flex>
    </Form>
  );
});

const SubTitleStyle = styled(Flex)`
  font-size: 22px;
  font-weight: bold;
`;
