import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Flex } from 'antd';
import { HospitalForm } from 'src/components/Organisms/Master/HospitalForm';
import { HospitalConfirm } from 'src/components/Organisms/Master/HospitalConfirm';
import { useHospitalMasterCreateHooks } from 'src/hooks/pages/use.hospital.master.create.hooks';
import { AccountForm } from 'src/components/Organisms/Account/AccountForm';

export const HospitalMasterCreate: FC = memo(() => {
  const {
    hospital,
    onConfirm,
    step,
    onCreate,
    onBack,
    account,
    onNext,
    areas,
    setArea,
    groups,
  } = useHospitalMasterCreateHooks();
  return (
    <Default
      title={'病院アカウント追加'}
      isSelect={5}
      contents={
        <Flex vertical={true} gap={8}>
          <div style={{ color: 'red' }}>※印は入力必須項目です。</div>
        </Flex>
      }
    >
      {step === 0 && (
        <AccountForm account={account} onConfirm={onNext} label={'次へ'} />
      )}
      {step === 1 && (
        <HospitalForm
          setArea={setArea}
          groups={groups}
          areas={areas}
          isEdit={false}
          hospital={hospital}
          onBack={onBack}
          onConfirm={onConfirm}
        />
      )}
      {step === 2 && (
        <HospitalConfirm
          isEdit={false}
          account={account}
          group={groups.find((group) => group.id === hospital.groupId)}
          hospital={hospital}
          onUpdate={onCreate}
          onBack={onBack}
        />
      )}
    </Default>
  );
});
