import { FC, memo } from 'react';
import { Button, Flex } from 'antd';

import { Default } from 'src/components/Layouts/default';
import { useScheduleCreateHooks } from 'src/hooks/pages/use.schedule.create.hooks';
import { ScheduleForm } from 'src/components/Organisms/Schedule/ScheduleForm';
import { ScheduleConfirm } from 'src/components/Organisms/Schedule/ScheduleConfirm';
import { SecondaryButton } from 'src/components/Atoms/SecondaryButton';

export const ScheduleCreate: FC = memo(() => {
  const { schedule, onCreate, step, onNext, onBack } = useScheduleCreateHooks();
  return (
    <Default
      title={'エントリースケジュール登録'}
      isSelect={5}
      contents={
        <Flex vertical={true} gap={8}>
          <div style={{ color: 'red' }}>※印は入力必須項目です。</div>
        </Flex>
      }
    >
      {step === 0 && <ScheduleForm schedule={schedule} onConfirm={onNext} />}
      {step === 1 && schedule && (
        <Flex vertical={true} gap={'large'} align={'center'} justify={'center'}>
          <ScheduleConfirm schedule={schedule} />
          <Flex
            justify={'center'}
            style={{ width: '100%', margin: '16px' }}
            gap={32}
          >
            <SecondaryButton onClick={onBack}>戻る</SecondaryButton>
            <Button type={'primary'} onClick={onCreate}>
              登録
            </Button>
          </Flex>
        </Flex>
      )}
    </Default>
  );
});
