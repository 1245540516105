import { LoginReq } from 'src/service/req/auth';
import { axiosInstance } from 'src/providers/axios.provider';
import { AxiosResponse } from 'axios';
import { LoginRes } from 'src/service/res/auth';

export const authRepository = {
  login: async (req: LoginReq) => {
    return await axiosInstance.post<LoginReq, AxiosResponse<LoginRes>>(
      '/auth/login',
      req,
    );
  },
};
