import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Button, Flex } from 'antd';
import {
  HospitalTable,
  PharmacyTable,
  SearchBox,
} from 'src/components/Organisms/Master';
import { useMasterInfoHooks } from 'src/hooks/pages/use.master.info.hooks';

export const MasterInfoSearch: FC = memo(() => {
  const {
    areas,
    onSearch,
    onExportHospital,
    onExportPharmacy,
    search,
    hospital,
    pharmacy,
    page,
    total,
    onPage,
  } = useMasterInfoHooks();
  return (
    <Default
      title={'実習施設検索'}
      isSelect={3}
      contents={
        <Flex vertical={true} gap={16}>
          <Flex gap={16} vertical={false} align={'center'} justify={'center'}>
            <Button
              type={'primary'}
              title={'全薬局リスト（EXCEL）'}
              onClick={onExportPharmacy}
            >
              全薬局リスト（EXCEL）
            </Button>
            <Button
              type={'primary'}
              title={'全病院リスト（EXCEL）'}
              onClick={onExportHospital}
            >
              全病院リスト（EXCEL）
            </Button>
          </Flex>
          ご覧になりたい対象を選択して「一覧を表示する」をクリックしてください。
        </Flex>
      }
    >
      <SearchBox onSearch={onSearch} areas={areas} />
      {search?.masterType == 1 && (
        <HospitalTable
          hospitals={hospital}
          page={page}
          total={total}
          onPage={onPage}
        />
      )}
      {search?.masterType == 2 && (
        <PharmacyTable
          pharmacies={pharmacy}
          page={page}
          total={total}
          onPage={onPage}
        />
      )}
    </Default>
  );
});
