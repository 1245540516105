import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Button, Flex, Modal } from 'antd';
import styled from 'styled-components';
import { useScheduleDetailHooks } from 'src/hooks/pages/use.schedule.detail.hooks';
import { ScheduleConfirm } from 'src/components/Organisms/Schedule/ScheduleConfirm';

export const ScheduleDetail: FC = memo(() => {
  const { schedule, setOpen, isOpen, onEdit, onCreate } =
    useScheduleDetailHooks();
  return (
    <Default
      title={'エントリースケジュール管理'}
      isSelect={5}
      contents={schedule ? schedule.name : ''}
    >
      {schedule && <ScheduleConfirm schedule={schedule} />}
      {schedule && (
        <Button type={'primary'} size={'large'} onClick={onEdit}>
          編集
        </Button>
      )}
      <Button type={'primary'} size={'large'} onClick={() => setOpen(true)}>
        新規スケジュール作成
      </Button>
      <Modal
        title={<ModalTile>新規スケジュールの作成</ModalTile>}
        open={isOpen}
        onOk={onCreate}
        centered={true}
        onCancel={() => setOpen(false)}
      >
        <Flex vertical={true} align={'center'}>
          <div>現在のスケジュールおよび</div>
          <div>エントリーの修正ができなくなります。</div>
          <div>よろしいですか？</div>
        </Flex>
      </Modal>
    </Default>
  );
});

const ModalTile = styled.div`
  text-align: center;
`;
