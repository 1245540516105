import { FC, memo, useCallback, useState } from 'react';
import { Flex, Typography } from 'antd';
import {
  CaretRightOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { GroupHospitalEntity } from 'src/domain/group.hospital.entity';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

type Props = {
  group: GroupHospitalEntity;
};
export const GroupHospital: FC<Props> = memo((props) => {
  const [isOpen, setOpen] = useState(true);
  const navigate = useNavigate();
  const onCellClick = useCallback((hospitalId: number) => {
    navigate(`/hospital/master/edit/${hospitalId}`);
  }, []);
  return (
    <Flex
      vertical={true}
      style={{
        alignSelf: 'stretch',
        border: '1px solid #00000070',
      }}
      align={'flex-start'}
    >
      <Flex
        vertical={false}
        align={'center'}
        justify={'space-between'}
        style={{
          height: '48px',
          padding: '0 16px',
          background: '#000000',
          alignSelf: 'stretch',
        }}
      >
        <Typography.Title
          level={3}
          style={{
            color: '#FFFFFF',
            margin: 0,
            padding: 0,
          }}
        >
          {props.group.areaName}
        </Typography.Title>
        {!isOpen && (
          <DownOutlined
            style={{ color: '#FFFFFF', fontSize: '22px' }}
            onClick={() => setOpen(true)}
          />
        )}
        {isOpen && (
          <UpOutlined
            style={{ color: '#FFFFFF', fontSize: '22px' }}
            onClick={() => setOpen(false)}
          />
        )}
      </Flex>
      {isOpen && (
        <HospitalListStyle vertical={true}>
          {props.group.hospitals.map((hospital) => (
            <HospitalCellStyle
              vertical={false}
              key={hospital.hospitalNo}
              justify={'space-between'}
              onClick={() => onCellClick(hospital.id)}
            >
              <Flex vertical={false} align={'center'}>
                <CaretRightOutlined size={20} />
                {hospital.name}
              </Flex>
              {hospital.capacity ? '今年度分反映済み' : '今年度分未反映'}
            </HospitalCellStyle>
          ))}
        </HospitalListStyle>
      )}
    </Flex>
  );
});

const HospitalListStyle = styled(Flex)`
  width: 100%;
  padding: 8px;
`;
const HospitalCellStyle = styled(Flex)`
  &:hover {
    color: #0000ee;
  }
`;
