import { FC, memo } from 'react';
import { Button, Flex, Form, Input, Select } from 'antd';
import { AuthName, AuthType } from 'src/common/types';
import { AccountSearchEntity } from 'src/domain/account.entity';

type Props = {
  onSearch: (form: AccountSearchEntity) => void;
};
export const AccountSearch: FC<Props> = memo((props) => {
  const [form] = Form.useForm<AccountSearchEntity>();
  return (
    <Flex vertical={true} align={'center'}>
      <Form layout="vertical" form={form}>
        <Flex vertical={false} gap={32}>
          <Form.Item label={'権限'} name={'authId'}>
            <Select placeholder={'権限'} allowClear>
              {Object.keys(AuthType)
                .filter((auth) => !isNaN(Number(auth)))
                .map((authId) => (
                  <Select.Option key={authId} value={Number(authId)}>
                    {AuthName[Number(authId) as AuthType]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label={'名前'} name={'name'}>
            <Input name={'name'} placeholder={'名前'} />
          </Form.Item>
          <Form.Item label={'ログインID'} name={'accountId'}>
            <Input name={'accountId'} placeholder={'ログインID'} />
          </Form.Item>
        </Flex>
      </Form>

      <Button
        type={'primary'}
        size={'large'}
        onClick={() => props.onSearch(form.getFieldsValue())}
      >
        検索
      </Button>
    </Flex>
  );
});
