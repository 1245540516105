import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Button, Flex, Typography } from 'antd';
import {
  HospitalEntryFooter,
  HospitalEntryList,
} from 'src/components/Organisms/Hospital';
import { useHospitalEntryHooks } from 'src/hooks/pages/use.hospital.entry.hooks';
import { EntryHeader } from 'src/components/Organisms/Common/entry.header';

export const HospitalEntry: FC = memo(() => {
  const {
    groups,
    entry,
    onChange,
    universities,
    onUniversitySelect,
    schedule,
    user,
    disabled,
    universityId,
    update,
    sumEntry,
  } = useHospitalEntryHooks();
  return (
    <Default
      title={`${user?.name} 病院実習エントリー`}
      isSelect={1}
      contents={
        '地区グループ名をクリックすると該当する病院が表示されます。　「希望数」の列に希望する実習生の人数を入力してください。'
      }
    >
      {user && (
        <EntryHeader
          authId={user?.authId}
          onSelect={onUniversitySelect}
          universities={universities}
          between={schedule?.hospitalEntry}
          between2={schedule?.hospitalReentry}
        />
      )}
      {universityId && (
        <Flex
          vertical={true}
          align={'flex-start'}
          style={{ alignSelf: 'stretch' }}
        >
          {groups.map((group) => (
            <HospitalEntryList
              key={group.areaId}
              group={group}
              entry={entry[group.areaId]}
              sumEntry={sumEntry}
              onChange={onChange}
              disabled={disabled}
            />
          ))}
        </Flex>
      )}
      {universityId && (
        <Button
          title={'反映'}
          type={'primary'}
          size={'large'}
          disabled={disabled}
          onClick={update}
        >
          登録
        </Button>
      )}
      {universityId && <HospitalEntryFooter entry={entry} />}
      {!universityId && (
        <Typography.Title level={5}>
          エントリーする大学を選択してください
        </Typography.Title>
      )}
    </Default>
  );
});
