import { FC, memo } from 'react';
import { Flex } from 'antd';
import { HospitalEntity } from 'src/domain/hospital.entity';
import styled from 'styled-components';
import { EntryEntity } from 'src/domain/entry.entity';
import { HospitalEntryEditCell } from 'src/components/Organisms/Hospital/HospitalEntryEditCell';

type Props = {
  isOpen: boolean;
  areaId: number;
  hospital: HospitalEntity;
  entry?: EntryEntity;
  sumEntry?: EntryEntity;
  onChange: (
    areaId: number,
    hospitalId: number,
    period: string,
    num: number,
  ) => void;
  disabled: boolean;
};
export const HospitalEntryRow: FC<Props> = memo((props) => {
  return (
    <Flex
      vertical={false}
      align={'flex-start'}
      style={{
        border: '1px solid #000000',
        visibility: props.isOpen ? 'inherit' : 'hidden',
        display: props.isOpen ? 'inherit' : 'none',
        alignSelf: 'stretch',
      }}
    >
      <CellStyle
        vertical={true}
        align={'start'}
        justify={'center'}
        style={{
          alignSelf: 'stretch',
          minWidth: '280px',
          flex: '1 0 0',
          height: 'inherit',
          padding: '0.5rem',
        }}
      >
        <div>{props.hospital.hospitalNo}</div>
        <div>{props.hospital.name}</div>
        <div>
          {props.hospital.group?.area.name}
          {props.hospital.group?.name}
        </div>
      </CellStyle>
      <Flex vertical={true} style={{ width: '160px' }}>
        <CellHeaderStyle
          vertical={false}
          style={{ padding: '0.5rem' }}
          align={'center'}
        >
          定員
        </CellHeaderStyle>
        <CellStyle style={{ padding: '8px' }} align={'center'} vertical={false}>
          エントリー
        </CellStyle>
        <CellHeaderStyle
          vertical={false}
          style={{ padding: '0.5rem' }}
          align={'center'}
        >
          全校エントリー
        </CellHeaderStyle>
      </Flex>
      <HospitalEntryEditCell
        areaId={props.areaId}
        hospitalId={props.hospital.id}
        capacity={props.hospital.capacity?.secondCount ?? 0}
        entry={props.entry?.secondCount}
        sum={props.sumEntry?.secondCount ?? 0}
        onChange={props.onChange}
        period={'secondCount'}
        disabled={props.disabled}
      />
      <HospitalEntryEditCell
        areaId={props.areaId}
        hospitalId={props.hospital.id}
        capacity={props.hospital.capacity?.thirdCount ?? 0}
        entry={props.entry?.thirdCount}
        sum={props.sumEntry?.thirdCount ?? 0}
        onChange={props.onChange}
        period={'thirdCount'}
        disabled={props.disabled}
      />
      <HospitalEntryEditCell
        areaId={props.areaId}
        hospitalId={props.hospital.id}
        capacity={props.hospital.capacity?.fourthCount ?? 0}
        entry={props.entry?.fourthCount}
        sum={props.sumEntry?.fourthCount ?? 0}
        onChange={props.onChange}
        period={'fourthCount'}
        disabled={props.disabled}
      />
    </Flex>
  );
});

const CellStyle = styled(Flex)`
  border: 1px solid #000000;
  height: 39px;
`;

const CellHeaderStyle = styled(CellStyle)`
  background: #000000;
  color: #ffffff;
  border-color: #ffffff;
`;
