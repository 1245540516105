import { FC, memo } from 'react';
import { Flex } from 'antd';
import { Default } from 'src/components/Layouts/default';
import { AccountForm } from 'src/components/Organisms/Account/AccountForm';
import { useAccountUniversityCreateHooks } from 'src/hooks/pages/use.account.university.create.hooks';
import { AccountConfirm } from 'src/components/Organisms/Account/AccountConfirm';

export const AccountUniversityCreate: FC = memo(() => {
  const { account, onCreate, onBack, onNext, step } =
    useAccountUniversityCreateHooks();
  return (
    <Default
      title={'大学アカウント追加'}
      isSelect={5}
      contents={
        <Flex vertical={true} gap={8}>
          <div style={{ color: 'red' }}>※印は入力必須項目です。</div>
        </Flex>
      }
    >
      {step === 0 && (
        <AccountForm account={account} onConfirm={onNext} label={'確認'} />
      )}
      {step === 1 && (
        <AccountConfirm account={account} onBack={onBack} onUpdate={onCreate} />
      )}
    </Default>
  );
});
