import { FC, memo, ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios, { HttpStatusCode } from 'axios';
import { InfoContext } from 'src/providers/info.provider';
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

type Props = {
  children: ReactNode;
};

export const AxiosInterceptor: FC<Props> = memo((props) => {
  const navigate = useNavigate();
  const { createMessage } = useContext(InfoContext);
  axiosInstance.interceptors.request.use(
    ($config) => {
      const user = localStorage.getItem('user');
      if (user) {
        $config.headers.Authorization = `Bearer ${JSON.parse(user).accessToken}`;
      }
      return $config;
    },
    (error) => {
      Promise.reject(error).then();
    },
  );

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      if (
        !err.request.responseURL.includes('login') &&
        err.response.status === 401
      ) {
        createMessage('ログインセッションが切れました。', 'error');
        localStorage.removeItem('user');
        navigate(`/login`);
        localStorage.setItem('redirectURL', location.href);
        return Promise.reject(err);
      } else if (err.response.status === 401) {
        createMessage('ログインに失敗しました。', 'error');
      } else if (err.response.status !== HttpStatusCode.InternalServerError) {
        createMessage(err.response.data, 'error');
      } else {
        createMessage('システムエラー', 'error');
      }

      return Promise.reject(err);
    },
  );

  return <>{props.children}</>;
});
