import { FC, memo } from 'react';
import { Default } from 'src/components/Layouts/default';
import { Flex } from 'antd';
import { NewsForm } from 'src/components/Organisms/News/NewsForm';
import { NewsConfirm } from 'src/components/Organisms/News/NewsConfirm';
import { useNewsCreateHooks } from 'src/hooks/pages/use.news.create.hooks';

export const NewsCreate: FC = memo(() => {
  const { news, onCreate, onBack, onNext, step } = useNewsCreateHooks();
  return (
    <Default
      title={'お知らせ登録'}
      isSelect={5}
      contents={
        <Flex vertical={true} gap={8}>
          <div style={{ color: 'red' }}>※印は入力必須項目です。</div>
        </Flex>
      }
    >
      {step === 0 && <NewsForm news={news} onConfirm={onNext} />}
      {step === 1 && (
        <NewsConfirm news={news} onBack={onBack} onCreate={onCreate} />
      )}
    </Default>
  );
});
